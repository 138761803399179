import { MdOutlineCompareArrows } from 'react-icons/md';
import { BiArrowToRight, BiArrowFromLeft } from 'react-icons/bi';
import { FaChessPawn } from 'react-icons/fa';
import { Colors } from '../../common/styles';
import { FontSizes, FontFamilies } from '../../common/styles/typography';
import { Tooltip } from 'antd';

function GrdServiceTypeIcon({grdServiceType, fontSize} : {grdServiceType:string | null, fontSize:string | null}) {
    switch(grdServiceType){
        case "Supply":
          return <Tooltip title="Supply" color={Colors.Neutral600}>
                    <BiArrowFromLeft style={{color:Colors.Primary300, fontSize:FontSizes.Header05}} />
                  </Tooltip>
        case "Return":
          return <Tooltip title="Return">
                   <BiArrowToRight style={{color:Colors.Secondary700, fontSize:FontSizes.Header05}}/>  
                 </Tooltip> 
        case "Transfer":
          return <Tooltip title="Return">
                    <MdOutlineCompareArrows />
                 </Tooltip>
        default: 
          return <FaChessPawn/>;
      }
}

export default GrdServiceTypeIcon