import { Progress, Tooltip } from 'antd';
import { Colors } from '../common/styles';
import { ITaskCompletionProgress } from '../state/interfaces';

const strokeWidth = 15;
const strokeLinecap = 'square'

function TaskProgressBar({progress}:{progress: ITaskCompletionProgress}) {
  
  if(progress.percentComplete === 100){
    return(
      <div style={{margin:'0px'}}>
        <p style={{margin:'0px 0px -5px 0px', color:Colors.Shade100, float:'left'}}><b>{progress.percentComplete}%</b></p>
        <Progress percent={progress.percentComplete} 
                  strokeColor={Colors.Success500} 
                  showInfo={false}
                  strokeWidth={strokeWidth} 
                  strokeLinecap={strokeLinecap}/>
        <p style={{margin:'0px', color:Colors.Neutral600}}>{progress.completedTasks} of {progress.totalTasks} tasks</p>
      </div>
    );
  }
  else{
    return (
        <div style={{margin:'0px'}}>
          <p style={{margin:'0px 0px -5px 0px', color:Colors.Shade0, float:'left'}}><b>{progress.percentComplete}%</b></p>
          <Progress percent={progress.percentComplete} 
                    strokeColor={Colors.Neutral600} 
                    showInfo={false}
                    strokeWidth={strokeWidth} 
                    strokeLinecap={strokeLinecap}/>
          <p style={{margin:'0px', color:Colors.Neutral600}}>{progress.completedTasks} of {progress.totalTasks} tasks</p>
        </div>
    );
  }
}

export default TaskProgressBar