import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import * as Paths from '../common/routes';

function NotFound() {
  return (
          <div>
            <h1>
              Oops!
              404 - Page not found!
            </h1>
            <Link to='/'>
              <FaHome className='mr-2' />
                Back To Home
            </Link>
          </div>
  )
}

export default NotFound