import { CommissioningActionTypes } from '../action-types';
import { CommissioningAction } from '../actions';
import { IEquipmentSystem, IEquipmentHeader } from '../interfaces';

//State-specific Interfaces
interface ICommissioningState{
    equipment:IEquipmentState;
    punchlists:IPunchListState;
}

interface IEquipmentState{
    data:IEquipmentSystem[];
    selectedSystem:IEquipmentSystem | null;
    selectedEquipmentHeader: IEquipmentHeader | null;
    isLoading: boolean;
    error: string | null;
    isSynced: boolean;
}

interface IPunchListState{
    isLoading: boolean, 
    isSynced: boolean,
    error: string | null
}

//Initial States
const initialEquipmentState : IEquipmentState = {
    data: [],
    selectedSystem:null,
    selectedEquipmentHeader:null,
    isLoading: false, 
    isSynced: true,
    error: null
} 

const initialPunchListState : IPunchListState = {
    isLoading: false, 
    isSynced: true,
    error: null
}

const initialState: ICommissioningState = {
    equipment: initialEquipmentState,
    punchlists: initialPunchListState
}



const reducer = (state: ICommissioningState = initialState, action: CommissioningAction) : ICommissioningState => {
        switch(action.type)
        {
            case CommissioningActionTypes.FETCH_JOB_EQUIPMENT:
                return {equipment: { isLoading: true,
                                     isSynced: true,
                                     error: null,
                                     data: [],
                                     selectedSystem: state.equipment.selectedSystem,
                                     selectedEquipmentHeader: state.equipment.selectedEquipmentHeader,
                                    },
                        punchlists: {isLoading: true,
                                     isSynced: true,
                                     error: null
                                    }};

            case CommissioningActionTypes.FETCH_JOB_EQUIPMENT_SUCCESS:
                return {equipment: { isLoading: false,
                                        isSynced: true,
                                        error: null,
                                        data: action.payload,
                                        selectedSystem: state.equipment.selectedSystem,
                                        selectedEquipmentHeader: state.equipment.selectedEquipmentHeader,
                                    },
                        punchlists: {isLoading: true,
                                        isSynced: true,
                                        error: null
                                    }};

            case CommissioningActionTypes.FETCH_JOB_EQUIPMENT_ERROR:
                return {equipment: { isLoading: false,
                                        isSynced: true,
                                        error: action.payload,
                                        data: [],
                                        selectedSystem: null,
                                        selectedEquipmentHeader: null,
                                    },
                        punchlists: {isLoading: true,
                                     isSynced: true,
                                     error: null
                                    }};
            
            case CommissioningActionTypes.SET_SELECTED_SYSTEM:
                return {equipment: { isLoading: false,
                                     isSynced: true,
                                     error: null,
                                     data: state.equipment.data,
                                     selectedSystem: action.payload !== null ? state.equipment.data.find((x) => x.id === action.payload)! : null,
                                     selectedEquipmentHeader: state.equipment.selectedEquipmentHeader,
                                   },
                        punchlists: {isLoading: true,
                                     isSynced: true,
                                     error: null
                                     }};
            case CommissioningActionTypes.SET_SELECTED_EQUIPMENT_HEADER:
            return {equipment: { isLoading: false,
                                    isSynced: true,
                                    error: null,
                                    data: state.equipment.data,
                                    selectedSystem: state.equipment.selectedSystem,
                                    selectedEquipmentHeader: action.payload !== null ?  state.equipment.selectedSystem?.headers.find((x) => x.id === action.payload)! : null,
                                },
                    punchlists: {isLoading: true,
                                 isSynced: true,
                                 error: null
                                 }};
            case CommissioningActionTypes.SAVE_AIR_BALANCES:
                return {equipment: { isLoading: true,
                                     isSynced: true,
                                     error: null,
                                     data: state.equipment.data,
                                     selectedSystem: state.equipment.selectedSystem,
                                     selectedEquipmentHeader: state.equipment.selectedEquipmentHeader,
                                    },
                        punchlists: {isLoading: true,
                                    isSynced: true,
                                    error: null
                                    }};
            case CommissioningActionTypes.SAVE_AIR_BALANCES_SUCCESS:
                return {equipment: { isLoading: false,
                                     isSynced: true,
                                     error: null,
                                     data: state.equipment.data,
                                     selectedSystem: action.payload,
                                     selectedEquipmentHeader: state.equipment.selectedEquipmentHeader,
                                    },
                        punchlists: {isLoading: true,
                                     isSynced: true,
                                     error: null
                                     }};
            case CommissioningActionTypes.SAVE_AIR_BALANCES_ERROR:
                return {equipment: { isLoading: false,
                                     isSynced: true,
                                     error: action.payload,
                                     data: state.equipment.data,
                                     selectedSystem: state.equipment.selectedSystem,
                                     selectedEquipmentHeader: state.equipment.selectedEquipmentHeader,
                                    },
                        punchlists: {isLoading: true,
                                     isSynced: true,
                                     error: null
                                     }};
            case CommissioningActionTypes.SAVE_STARTUP_INFO:
                return {equipment: { isLoading: true,
                                     isSynced: true,
                                     error: null,
                                     data: state.equipment.data,
                                     selectedSystem: state.equipment.selectedSystem,
                                     selectedEquipmentHeader: state.equipment.selectedEquipmentHeader,
                                    },
                        punchlists: {isLoading: true,
                                    isSynced: true,
                                    error: null
                                    }};
            case CommissioningActionTypes.SAVE_STARTUP_INFO_SUCCESS:
                return {equipment: { isLoading: false,
                                     isSynced: true,
                                     error: null,
                                     data: state.equipment.data,
                                     selectedSystem: {
                                         ...state.equipment.selectedSystem!, 
                                         headers: state.equipment.selectedSystem!.headers.map(
                                             (header) => header.id === action.payload.id ? action.payload : header )
                                     },
                                     selectedEquipmentHeader: state.equipment.selectedEquipmentHeader == null ?? state.equipment.selectedEquipmentHeader!.id === action.payload!.id ? action.payload : state.equipment.selectedEquipmentHeader,
                                    },
                        punchlists: {isLoading: true,
                                     isSynced: true,
                                     error: null
                                     }};
            case CommissioningActionTypes.SAVE_STARTUP_INFO_ERROR:
                return {equipment: { isLoading: false,
                                     isSynced: true,
                                     error: action.payload,
                                     data: state.equipment.data,
                                     selectedSystem: state.equipment.selectedSystem,
                                     selectedEquipmentHeader: state.equipment.selectedEquipmentHeader,
                                    },
                        punchlists: {isLoading: true,
                                     isSynced: true,
                                     error: null
                                     }};
                                         
            default: 
                return state;
        }
    };

export default reducer;

