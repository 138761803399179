import React from 'react';
import { Colors } from './colors';

export const layoutStyle = {
  // position:'fixed', 
  // width:'100%',
  // height:'1400px',
  height:'100%',
  backgroundColor:'hotpink',
  padding:'0px',
} as const;

export const siderStyle = {
    backgroundColor: 'white',
} as const
  
export const headerStyle = { 
    backgroundColor: Colors.Neutral400
  }
  
export const contentStyle = {
    padding: '30px 30px',
    backgroundColor: Colors.Shade0,
    // backgroundColor: '#ee6f2d',
  } as const

export const footerStyle = {
    backgroundColor: Colors.Shade0,
}

export const LayoutValues = {
    SiderWidth:75,
}