import { useRef, useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { FaRegCopyright } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';
import Spinner from "../components/shared/Spinner";
import { Shared } from '../common/routes';
import { IUserLoginRequest } from "../state/api";
import * as Colors from '../common/color-themes';
import { useTypedSelector } from "../common/hooks/useTypedSelector";
import { AttemptLogin } from "../state/action-creators";

const graphic = require('../common/assets/static/constructionTransparent.png');
const logo = require('../common/assets/static/lightLogoTransparent.png');

const Login = () => {
    
    const { data, error, isLoading, isLoggedIn } = useTypedSelector((state) => state.team.user);
    const navigate = useNavigate();

    const userRef = useRef<HTMLInputElement>(null);
    const errRef = useRef<HTMLParagraphElement>(null);
    
    const dispatch = useDispatch();
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    useEffect(() => {
        if(data != null && isLoggedIn){
            navigate('/');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isLoggedIn]);
    
    useEffect(() => {
        userRef.current?.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        try {
            
            const loginRequest: IUserLoginRequest = {
                username: user,
                email: user,
                password: pwd,
              };
              
            await AttemptLogin(dispatch, loginRequest).then((response) =>{
                if(response === true){
                    console.log('login success');
                    navigate('/');
                }else{
                    console.log('login fail');
                    navigate(Shared.Login);
                }
            });

            console.log(`NODE_ENV:  + ${process.env.NODE_ENV}`);

        } catch (err) {
            if(err instanceof Error)
            { 
                if(!Response){
                    setErrMsg('No Server Response');
                }
            }
            errRef.current?.focus();
        }
    }

    return (
      <div>
        { error && <h3>An Error Occured: { error }</h3>}
        { isLoading && <Spinner/> }
        { !error && !isLoading &&  
        <div style={{display: 'flex', height:'100vh'}}>
            {/* COLORED PANEL */}
            <div className="colored-panel">
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', marginTop:'8rem'}}>
                    <div style={{width:'20%'}}>
                        <img src={logo}
                            alt="Aire Logo"
                            width="100%"
                            />
                    </div>
                    
                    <div style={{width:'50%'}}>
                        <img src={graphic} 
                            alt="Construction Team"
                            width='100%'
                            />
                    </div>
                    <div>
                        <span style={{color:'white', verticalAlign:'center', fontSize:'10pt'}}>Aire <FaRegCopyright style={{height:'10px'}}/>2023</span>
                    </div>
                </div>
                
            </div>
            {/* LOGIN FORM */}
            <div style={{flex:1, backgroundColor:Colors.Theme.Shade0, display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
                
                <div className="login-form-container" style={{marginTop:'20%'}}>
                    <h1 style={{fontSize:'42pt', fontFamily:'Raleway', color:Colors.Theme.Neutral700, textAlign:'center', margin:'0px', lineHeight:'1'}}>Welcome Back</h1>
                        
                            <div className="marg-top-m" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <p style={{fontSize:'12pt', margin:'0px 15px 0px 0px'}}>Don't have an account? </p>
                                {/* <Link to={Shared.Register}>Sign Up</Link> */}
                                <button className="signup-button"
                                        onClick={() => { navigate(Shared.Register) }}
                                        >Sign Up
                                </button>
                            </div>    
                            
                    <form onSubmit={handleSubmit}>
                        <div className="stack-container marg-top-l">
                            {/* EMAIL ADDRESS */}
                            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

                            <label className="login-field-label" htmlFor="username">
                                Email
                            </label>
                            <div className="login-field-container">
                                <input className="login-field"
                                    type="text"
                                    tabIndex={1}
                                    id="username"
                                    ref={userRef}
                                    autoComplete="off"
                                    onChange={(e) => setUser(e.target.value)}
                                    value={user}
                                    required
                                    />
                            </div>
            
                            {/* PASSWORD */}
                            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                <label className="login-field-label" htmlFor="password">
                                    Password
                                </label>
                                <Link to={Shared.LostPassword}>Forgot password?</Link>
                            </div>
                            <div className="login-field-container" >
                                <input className="login-field"
                                    tabIndex={2}
                                    type="password"
                                    id="password"
                                    onChange={(e) => setPwd(e.target.value)}
                                    value={pwd}
                                    required
                                    aria-describedby="pwdnote"
                                />
                            </div>
            
                            <div className="marg-top-l" 
                                style={{display:'flex', alignItems:'center'}}>
                                <input type="checkbox" 
                                    className="marg-left-s"
                                        id="remember-me"
                                        checked={rememberMe}
                                        onChange={() => { setRememberMe(!rememberMe)}}
                                        style={{ height:'18px', 
                                                width:'18px',
                                                }}
                                        />
                                <span className="marg-left-s">Remember Me</span>
                            </div>

                            {/* <button disabled={!validName || !validPwd || !validMatch ? true : false}>Sign Up</button> */}
                            <button className="login-button h-fill marg-top-l" tabIndex={3}>
                                Login
                            </button>

                        </div>
                    </form>
                    
                </div>
            </div>
        </div>
        }
      </div>
    )
}

export default Login