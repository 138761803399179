import { useActions } from '../../common/hooks/useActions';
import { useEffect } from 'react';
import JobSearch from '../../components/JobSearch';
import JobSearchResults from '../../components/JobSearchResults';
import { Layout } from 'antd';
import { siderStyle, LayoutValues, layoutStyle, contentStyle} from '../../common/styles';

const logo = require('../../common/assets/static/lightLogoTransparent.png');
const spinner = require('../../common/assets/loading/pika.gif')
const { Header, Footer, Sider, Content } = Layout;

function Home(){

  const { fetchFeatureFlags } = useActions();

  useEffect(() => {
    fetchFeatureFlags();
  }, [])

  return (
    <div>
      <Layout style={layoutStyle}>
      <Sider width={LayoutValues.SiderWidth} style={siderStyle}>
          <div style={{height:'100%',
                      width:'100%', 
                      borderRight:'2px solid #cfcfcf',
                      boxShadow:'0px 0px 10px #bfbfbf',
                      display:'flex',
                      flexDirection:'column',
                      alignItems:'center',
                      }}>
              <img src={logo} style={{width:'40px', marginTop:'15px'}} />
          </div>
      </Sider>
      <Layout>
        {/* <Header style={headerStyle}>Navigation/Header</Header> */}
        <Content style={contentStyle}>
          <JobSearch/>
          <JobSearchResults />
        </Content>
      </Layout>
    </Layout>  
      
    </div>
  )
}

export default Home;

