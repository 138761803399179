import { useTypedSelector } from '../../common/hooks/useTypedSelector';
import { useActions } from '../../common/hooks/useActions';
import { useEffect } from 'react';
import { MdAccountTree } from 'react-icons/md';
import { AiFillControl, AiOutlineControl } from 'react-icons/ai';
import { ImSwitch } from 'react-icons/im';
import { GoCircuitBoard } from 'react-icons/go';
import { FaListOl, FaFan, FaBalanceScale } from 'react-icons/fa';
import SystemDetailTree from '../../components/system-detail-panel/SystemDetailTree';
import SystemAirBalance from '../../components/system-detail-panel/SystemAirBalance';
import UnitStartupInfo from '../../components/special-teams/UnitStartupInfo';
import { layoutStyle, siderStyle, LayoutValues, contentStyle } from '../../common/styles';
import { Layout, Tabs, Collapse } from 'antd';
import '../styles/system-page.css';

const logo = require('../../common/assets/loading/pika.gif');
const { Sider, Content } = Layout;
const { TabPane } = Tabs;
const { Panel } = Collapse;

function System() {

  const { selectedSystem } = useTypedSelector((state) => state.commissioning.equipment );
  const { selectedJob } = useTypedSelector((state) => state.jobs.activeJobs);
  const { headers } = useTypedSelector((state) => state.commissioning.equipment.selectedSystem!);
  const { features } = useTypedSelector((state) => state.configs)

  const detailsTabFirst = features.flags.find((f) => f.name === 'system-details-tab-first');

  const headerFontSize = '20px';

  return (
    <div>
      <Layout style={layoutStyle}>
          <Sider width={LayoutValues.SiderWidth} style={siderStyle}>
            <div style={{height:'100%',
                         width:'100%', 
                         borderRight:'2px solid #cfcfcf',
                         boxShadow:'0px 0px 10px #bfbfbf',
                         display:'flex',
                         flexDirection:'column',
                         alignItems:'center',
                         }}>
              <img src={logo} style={{width:'40px', marginTop:'15px'}} />
            </div>
          </Sider>
        <Layout>
          
          <Content style={contentStyle}>
              {/* <div className="job-page-breadcrumb-container">
                <a style={{color:'#afafaf'}}>Home  &gt; </a>
                <a style={{color:'#afafaf'}}>Selected Job &gt; </a>
                <a style={{color:'#6f6f6f'}}>{selectedJob!.number}</a>
              </div> */}

              <div style={{display:'flex'}}>
                <div className="system-tree-container">
                  <h1 style={{fontSize:'22px', fontWeight:'400'}}>{selectedSystem!.systemName}</h1>
                  <SystemDetailTree />
                </div>

                { detailsTabFirst!.on &&
                  <div className="system-tabs-container">
                    <Tabs defaultActiveKey='1' tabBarStyle={{color:'#8f8f8f', padding:'30px' }} size='large' style={{backgroundColor:''}}>
                      <TabPane tab={<div><span><FaFan />&nbsp; Air Balance</span> </div>} key='1'>
                        <Collapse accordion className="tab-container">
                          {headers !== null && 
                            headers.map((header) => {
                              if(header.isPrimeMover){
                                return(
                                  <Panel header={header.tag} key={header.id} style={{backgroundColor:'#dfdfdf'}}>
                                      <div style={{minHeight:'300px', backgroundColor:'#fafafa'}}>
                                        <SystemAirBalance primeMoverId={header.id} />
                                      </div>
                                  </Panel>
                                )
                              }               
                            })
                          }
                        </Collapse>
                      </TabPane>
                      <TabPane tab={<span><ImSwitch /> &nbsp; Startup</span>} key='2'>
                        <Collapse accordion className="tab-container">
                            {headers !== null && 
                              headers.map((header) => {
                                if(header.startupConfig != null){
                                  return(
                                    <Panel header={header.tag} key={header.id} style={{backgroundColor:'#dfdfdf'}}>
                                        <div style={{minHeight:'300px', backgroundColor:'#fafafa'}}>
                                          <UnitStartupInfo equipmentHeader={header} />
                                        </div>
                                    </Panel>
                                  )
                                }               
                              })
                            }
                        </Collapse>
                      </TabPane>
                      <TabPane tab={<span><FaListOl /> &nbsp; Sequence</span>} key='3'>
                      </TabPane>
                      <TabPane tab={<span><AiFillControl />&nbsp;  Controls</span>} key='4'>
                      </TabPane>
                      <TabPane tab={<span><MdAccountTree />&nbsp;  Accessories</span>} key='5'>
                      </TabPane>
                      <TabPane tab={<span><GoCircuitBoard />&nbsp; Schematics/Assets</span>} key='6'>
                      </TabPane>
                    </Tabs>
                  </div> }


              </div>
              
              </Content>
        </Layout>
      </Layout>  
    </div>
  )
}

export default System

