import axios from './axios'
import * as routes from './routes';

export const JobAPI = {
  getAll: async function(){
    const response = await axios.get(routes.Jobs.GetAll, {});
    return response;
  }

  
}