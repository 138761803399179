import { JobActionTypes } from '../action-types';
import { JobAction } from '../actions';
import { IActiveJobsState, IJobsState } from '../interfaces';
import { stat } from 'fs';

//Initial States
const initialActiveJobsState : IActiveJobsState = {
    selectedJob: null,
    data: [],
    isLoading: false, 
    error: null
} 

const initialState: IJobsState = {
    activeJobs:initialActiveJobsState
}

const reducer = (
    state: IJobsState = initialState, action: JobAction) : IJobsState => {
        switch(action.type)
        {
            case JobActionTypes.SEARCH_ACTIVE_JOBS:
                return {activeJobs:{ isLoading: true, 
                                     error: null, 
                                     data: [], 
                                     selectedJob: state.activeJobs.selectedJob}};
            case JobActionTypes.SEARCH_ACTIVE_JOBS_SUCCESS:
                return {activeJobs:{ isLoading: false, 
                                     error: null, 
                                     data: action.payload, 
                                     selectedJob: state.activeJobs.selectedJob}};
            case JobActionTypes.SEARCH_ACTIVE_JOBS_ERROR:
                return {activeJobs:{ isLoading: false, 
                                     error: action.payload, 
                                     data: [], 
                                     selectedJob: state.activeJobs.selectedJob}};
            case JobActionTypes.SET_SELECTED_JOB:
                return {activeJobs: {isLoading: false, 
                                     error: null, 
                                     data: state.activeJobs.data, 
                                     selectedJob: state.activeJobs.data.find((x) => x.id === action.payload)!}}
                                         
            default: 
                return state;
        }
    };

export default reducer;

// const reducer = (
//     state: IActiveJobsState = initialState, action: Action) : IActiveJobsState => {
//         switch(action.type)
//         {
//             case ActionType.SEARCH_ACTIVE_JOBS:
//                 return { isLoading: true, error: null, data: []};
//             case ActionType.SEARCH_ACTIVE_JOBS_SUCCESS:
//                 return { isLoading: false, error: null, data: action.payload};
//             case ActionType.SEARCH_ACTIVE_JOBS_ERROR:
//                 return { isLoading: false, error: action.payload, data: []};
//             default: 
//                 return state;
//         }
//     };