import { Collapse, Table, Button, Form, Input, Tooltip } from 'antd';
import GrdServiceTypeIcon from '../special-teams/GrdServiceTypeIcon';
import Spinner from '../../components/shared/Spinner';
import { BiArrowToRight, BiArrowFromLeft } from 'react-icons/bi';
import { FaSignInAlt, FaSignOutAlt } from 'react-icons/fa';
import { IUnitAirBalance, IEquipmentHeader } from '../../state/interfaces';
import { useActions } from '../../common/hooks/useActions';
import { useTypedSelector } from '../../common/hooks/useTypedSelector';
import { useState, useEffect } from 'react';
import { Colors } from '../../common/styles';
import { FormatPainterFilled } from '@ant-design/icons';
import "../styles/system-air-balance.css";

const { Panel } = Collapse;


function SystemAirBalance({primeMoverId} : {primeMoverId:number}) {

    const { selectedSystem, isLoading } = useTypedSelector((state) => state.commissioning.equipment);
    const { saveAirBalances } = useActions();

    //Individual air balances
    const [tableData, setTableData ] = useState<any[]>([]);
    
    //Air balance total quantities
    const [totalDesignSupplyCFM, setTotalDesignSupplyCFM] = useState<number>(0);
    const [totalInitialSupplyCFM, setTotalInitialSupplyCFM] = useState<number>(0);
    const [totalFinalSupplyCFM, setTotalFinalSupplyCFM] = useState<number>(0);

    const [totalDesignReturnCFM, setTotalDesignReturnCFM] = useState<number>(0);
    const [totalInitialReturnCFM, setTotalInitialReturnCFM] = useState<number>(0);
    const [totalFinalReturnCFM, setTotalFinalReturnCFM] = useState<number>(0);

    const [totalDesignOutsideAirCFM, setTotalDesignOutsideAirCFM] = useState<number>(0);
    const [totalFinalOutsideAirCFMFinal, setTotalFinalOutsideAirCFM] = useState<number>(0);
    const [testDate, setTestDate] = useState<string>('');
    const [testedBy, setTestedBy] = useState<string>('');

    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
    useEffect(() => {

      setTableData(fetchData(primeMoverId));
      //Set calculated fields on initial render

    }, [])

    const assignAirBalances = (airBalances:any, header:any) : IUnitAirBalance | null => {
    
      if(airBalances.length === 0){
          return null;
      }
      
      const balance = airBalances.find((a:any) => a.id === header.scheduleUnit.AirBalanceId)
      
      const headerBalance =  balance === null || balance === undefined ? null : {
          id: balance.id,
          testDate: balance.testDate,
          testedBy: balance.testedBy,
          designCFM: balance.designCFM,
          initialObservedCFM: balance.initialObservedCFM,
          finalObservedCFM: balance.finalObservedCFM,
          percentAccuracy: balance.percentAccuracy,
          systemHeaderId: selectedSystem!.id,
      }

      return headerBalance;
      
    }
    const assignAirBalanceTotals = (viewmodels:any[]) => {

      //this method will run on load (fetchData) and on input change (onInputChange)

      //calculate total design supply 
      const totalDesignSupply = viewmodels.filter((v) => v.serviceType === 'Supply').reduce((partialSum:number, a) => partialSum + a.designCFM, 0)
      setTotalDesignSupplyCFM(totalDesignSupply);

      //calculate total initial supply reading
      const totalInitialSupplyCFM = viewmodels.filter((v) => v.serviceType === 'Supply').reduce((partialSum, a) => partialSum + a.initialCFM, 0);
      setTotalInitialSupplyCFM(totalInitialSupplyCFM);
      
      //calculate total final supply reading
      const totalFinalSupplyCFM = viewmodels.filter((v) => v.serviceType === 'Supply').reduce((partialSum, a) => partialSum + a.finalCFM, 0);
      setTotalFinalSupplyCFM(totalFinalSupplyCFM);

      //design return
      const totalDesignReturn = viewmodels.filter((v) => v.serviceType === 'Return').reduce((partialSum:number, a) => partialSum + a.designCFM, 0)
      setTotalDesignReturnCFM(totalDesignReturn);
    
      //initial return
      const totalInitialReturn = viewmodels.filter((v) => v.serviceType === 'Return').reduce((partialSum:number, a) => partialSum + a.initialCFM, 0)
      setTotalInitialReturnCFM(totalInitialReturn);

      //final return
      const totalFinalReturn = viewmodels.filter((v) => v.serviceType === 'Return').reduce((partialSum:number, a) => partialSum + a.finalCFM, 0)
      setTotalFinalReturnCFM(totalFinalReturn);
    }
    const fetchData = (primeMoverId:number) : any[] => {
        const data = selectedSystem!.headers!.filter((header) => header.scheduleUnit.PrimeMoverEquipmentHeaderId === primeMoverId)
                                             .map((h) => { return {...h, airBalance:assignAirBalances(selectedSystem!.airBalances, h)} });

        const viewmodels = data.map((header: IEquipmentHeader) => {
          return({
                id:header.id!,
                tag: header.tag,
                mfr:header.mfr,
                model:header.model,
                serviceType:header.scheduleUnit.ServiceType,
                faceSize:header.scheduleUnit.RectangularFaceSize,
                neckSize:header.scheduleUnit.RectangularNeckSize,
                mountType:header.scheduleUnit.Mounting,
                airBalanceId:header.airBalance!.id,
                designCFM: header.airBalance!.designCFM,
                initialCFM:header.airBalance!.initialObservedCFM,
                finalCFM: header.airBalance!.finalObservedCFM,
                percent:header.airBalance!.percentAccuracy,
            })
        })

        assignAirBalanceTotals(viewmodels);

        return viewmodels.sort(function(a,b){
          return b.serviceType.localeCompare(a.serviceType);
        })
    }
    const onInputChange = (key:string, index:number) => (
      e:React.ChangeEvent<HTMLInputElement>
    ) => {
      const newData : any[] = [...tableData];
      newData[index][key] = Number(e.target.value);
      setPercent(newData, index);
      setTableData(newData);
      assignAirBalanceTotals(newData);
      setSaveDisabled(false);
    }
    const setPercent = (data:any, index:any) => {

      const finalCFM = Number(data[index]["finalCFM"]);

      if(finalCFM === null || finalCFM === 0){
        data[index]['percent'] = Number(data[index]["initialCFM"] / data[index]["designCFM"]);
      }else{
        data[index]['percent'] = Number(data[index]["finalCFM"] / data[index]["designCFM"])
      }
    }
    const onSave = () => {

      const airBalances : IUnitAirBalance[] = tableData.map((d) : IUnitAirBalance => {
        return {
          id: d.airBalanceId,
          testDate:'today',
          testedBy:'me',
          designCFM: d.designCFM,
          initialObservedCFM: d.initialCFM,
          finalObservedCFM: d.finalCFM,
          percentAccuracy: d.percent,
          systemHeaderId: selectedSystem!.id,
        }
      })

      saveAirBalances({ selectedSystem:selectedSystem!, editedAirBalances: airBalances});
      setSaveDisabled(true);
    }
    const columns = [
      {
        title:'Service',
        dataIndex:'serviceType',
        key:'id',
        width:'75px',
        render:(text:string, record:any) => {
          if (text === 'Supply'){
            return (
                <div className="service-type-block">
                  <Tooltip title="Supply" className="service-type-block">
                    <div className="service-type-block">
                      <BiArrowFromLeft style={{color:Colors.Primary500, fontSize:'32px'}} />
                    </div>
                  </Tooltip>
                </div>
              )
            }else if(text === 'Return'){
              return (
                <div className="service-type-block">
                  <Tooltip title="Return">
                    <BiArrowToRight style={{color:Colors.Primary500, fontSize:'32px'}} />
                  </Tooltip>
                </div>
              )
          }else if(text === 'Transfer'){
            return (
              <div className="service-type-block">
                <Tooltip title="Transfer">
                  <span>T</span>
                </Tooltip>
              </div>
          )
        }
          
      }
      },
      {
        title:'Tag',
        dataIndex:'tag',
        key:'id',
        render:(text:string, record:any) => {
            return (
                <div>{text}</div>
            )
        }
      },
      // {
      //   title:'Record Id',
      //   key:'id',
      //   render:(text:string, record:any) =>{
      //     return <p>{record.id}</p>
      //   }
      // },
      // {
      //   title:'Actions',
        
      // },
      {
        title:'Mfr',
        dataIndex:'mfr',
        key:'id',
      },
      {
        title:'Model',
        dataIndex:'model',
        key:'id',
      },
      {
        title:'Face Size',
        dataIndex:'faceSize',
        key:'id',
        
      },
      {
        title:'Neck Size',
        dataIndex:'neckSize',
        key:'id',
      },
      {
        title:'Mount Type',
        dataIndex:'mountType',
        key:'id',
      },
      {
        title:'Design CFM',
        dataIndex:'designCFM',
        key:'id',
        width:'200px',
      },
      {
        title:'Initial CFM',
        dataIndex:'initialCFM',
        key:'id',
        width:'200px',
        render:(text:string, record:any, index:number) => (
          <Input value={text} 
                 onChange={onInputChange("initialCFM", index)} 
                 />
        )
      },
      {
        title:'Final CFM',
        dataIndex:'finalCFM',
        key:'id',
        width:'200px',
        render:(text:string, record:any, index:number) => (
          <div>
            <Input value={text} 
                   onChange={onInputChange("finalCFM", index)} 
                   />
          </div>
        )
      },
      {
        title:'%',
        dataIndex:'percent',
        key:'id',
        width:'200px',
        render:(text:string, record:any, index:number) => (
          <div>
            {record.percent !== null && 
              <div>{(100*record.percent).toFixed(1)}</div>
            }
          </div>          
        )
      }
    ]

    return (

      <div>
        { isLoading === true && 
          <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><Spinner /></div>
        }
        { isLoading === false && 
          <div>
            {/* Header */}
            <p>Air Balance Totals</p>
            <div className='air-balance-totals-panel'>
              
              <div className='air-balance-totals'>
                <h1>Outside Air</h1>
                <p>Design: {totalDesignSupplyCFM}</p>
                <p>Final: {totalFinalSupplyCFM}</p>
              </div>

              <div className='air-balance-totals'>
                <h1>Supply</h1>
                <p>total design supply {totalDesignSupplyCFM}</p>
                <p>total initial supply {totalInitialSupplyCFM}</p>
                <p>total final supply {totalFinalSupplyCFM}</p>
              </div>

              <div className='air-balance-totals'>
                <h1>Return</h1>
                <p>total design return {totalDesignReturnCFM}</p>
                <p>total intial return {totalInitialReturnCFM}</p>
                <p>total final return {totalFinalReturnCFM}</p>
              </div>

            </div>
            <Button type="primary" onClick={onSave} disabled={saveDisabled}>Save</Button>
            <Table bordered
                   pagination={false}
                   dataSource={tableData}
                   columns={columns}
                   rowKey={(record:any) => record.id}
                   rowClassName={(record) => record.serviceType === 'Supply' ? "supply-grd" : "return-grd"}
                   />
          </div>
        }
      </div>
    )
}

export default SystemAirBalance