import { DownOutlined } from '@ant-design/icons';
import { FaChessKing, FaChessPawn } from 'react-icons/fa';
import { IoEnterOutline, IoEnterSharp, IoEnter, IoExitOutline, IoExit, IoExitSharp } from 'react-icons/io5';
import { MdOutlineCompareArrows } from 'react-icons/md';
import { RiArrowLeftRightFill } from 'react-icons/ri';
import { GiHamburgerMenu } from 'react-icons/gi';
import { BiArrowToRight, BiArrowFromLeft } from 'react-icons/bi';
import { RiFileListFill } from 'react-icons/ri';
import { ScheduleUnit } from '../../common/enums';
import { Form, Switch, Tree, Tooltip, TreeProps } from 'antd';
import { useState, useEffect } from 'react';
import { IEquipmentHeader } from '../../state/interfaces';
import { Colors } from '../../common/styles';

function SystemTree ({units} : { units: IEquipmentHeader[]}) {

  useEffect(() => {
    setSystemUnits(units);
    populateSystemTree(units);
  }, [units])

  const [ systemUnits, setSystemUnits ] = useState<any[]>();

  const populateGRDArray = (grds: IEquipmentHeader[]) : any[] => {
    return grds.length === 0 ? [] :
    [
      {
        title:'GRD',
        key:'GRD',
        selectable:false,
        icon:<GiHamburgerMenu style={{color:Colors.Primary500}}/>,
        children: grds.map((unit) => { 
                    return {
                      title:(() => {
                        return(
                          <span>{unit.tag}<i>{unit.location === '' || unit.location === null ? ' (No location given)' : ` - ${unit.location}`}</i></span>
                        );
                      }),
                      key:unit.id,
                      selectable:false,
                      icon:(() => {
                        switch(unit.scheduleUnit!.ServiceType){
                          case "Supply":
                            return <Tooltip title="Supply" color={Colors.Neutral600}>
                                      <BiArrowFromLeft style={{color:Colors.Primary500, fontSize:'22px'}} />
                                    </Tooltip>
                          case "Return":
                            return <Tooltip title="Return">
                                     <BiArrowToRight style={{color:Colors.Primary500, fontSize:'22px'}}/>  
                                   </Tooltip> 
                          case "Transfer":
                            return <Tooltip title="Return">
                                      <MdOutlineCompareArrows />
                                   </Tooltip>
                          default: 
                            return <FaChessPawn/>;
                        }
                      }) 
                    }
                  })
      }
    ]
  }

  const populateSecondaryArray = (units: IEquipmentHeader[]) : any[] => {
    return units.length === 0 ? [] :
      units.map((unit:IEquipmentHeader) => {
        return {
          title:unit.tag,
          key:unit.tag,
          selectable:false,
          icon:<FaChessPawn style={{color:Colors.Primary500}} />
        }
      })
  }

  const populateSystemTree = (units: IEquipmentHeader[]) => {

    const primaries: IEquipmentHeader[] = units.filter((unit) => {
      return unit.isPrimary === true
    });
    const grds: IEquipmentHeader[] = units.filter((unit)=> {
       return unit.type === ScheduleUnit.GRD
    });
    const secondaries: IEquipmentHeader[] = units.filter((unit) => {
      return unit.type !== ScheduleUnit.GRD && unit.isPrimary !== true
    })
  
    const systemGrds = populateGRDArray(grds);
    const systemSecondaries = populateSecondaryArray(secondaries);
    const systemChildren = [...systemSecondaries, ...systemGrds];

    const systemTree = [
      {
        title: primaries.length === 0 ? 'No Primary' : (() => {
          return(
            <h4 style={{margin:'0px'}}>
              <FaChessKing style={{color:Colors.Primary500}}/> {primaries[0].tag} 
              <span style={{fontWeight:'400'}}>
                <i> (Primary)</i>
              </span>
            </h4>
          )
        }),
        key: primaries.length === 0 ? 'No Primary' : primaries[0].tag,
        // icon:<FaChessKing style={{color:Colors.Primary500}}/>,
        selectable:false,
        children: systemChildren
        
      }
    ]
  
    setSystemUnits(systemTree);
  }

  const onSelect = (selectedKeys:any, info:any) => {
    console.log('selected', selectedKeys, info);
  };



  return (
    <div style={{width:'400px', alignSelf:'flex-start'}}>
      <Tree
        showLine={false}
        showIcon={true}
        defaultExpandAll={true}
        autoExpandParent={true}
        switcherIcon={<DownOutlined />}
        defaultExpandedKeys={['0-0-0']}
        onSelect={onSelect}
        treeData={systemUnits}
      />
    </div>
  );
};

export default SystemTree;