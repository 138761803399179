import { useActions } from '../common/hooks/useActions';
import { useTypedSelector } from '../common/hooks/useTypedSelector';
import React, { useEffect, useState } from 'react';
import { Input, Typography, Space } from 'antd';

const { Text, Paragraph, Title,  } = Typography;
const { Search } = Input;

function JobSearch() {

  const { fetchActiveJobs } = useActions();

  useEffect(() => {
    fetchActiveJobs('');
  }, [])

  const handleClick = (value : string) => {
    fetchActiveJobs(value);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.value.length === 0)
    {
      fetchActiveJobs('');
    }
  }

  return (
      <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginBottom:'50px', marginTop:'20px'}}>
          <Title level={2} style={{fontWeight:'400'}}>Search active jobs</Title>
          <Search
            size="large"
            allowClear
            bordered
            style={{width: '700px'}}
            placeholder='Search jobs...'
            onSearch={handleClick}
            onChange={handleChange}
           />
        
      </div>
  )
}

export default JobSearch