import { useRef, useState, useEffect } from "react";
import { FaRegCopyright, FaTimes, FaCheck, FaInfoCircle, FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { SpecialTeams, Shared } from '../common/routes';

import axios from '../state/api/axios';
import * as Colors from '../common/color-themes';

const graphic = require('../common/assets/static/constructionTransparent.png');
const logo = require('../common/assets/static/lightLogoTransparent.png');
const primary = Colors.Theme.Primary500;
const darkPrimary = Colors.Theme.Primary700

const USER_REGEX = /^[a-zA-Z0-9!@#$&()]{3,23}$/;
const PWD_REGEX = /^[A-z][A-z0-9-_]{7,23}$/;
const LOGIN_URL = 'banana/login';

const Register = () => {
    const userRef = useRef<HTMLInputElement>(null);
    const errRef = useRef<HTMLParagraphElement>(null);
    const navigate = useNavigate();

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        userRef.current?.focus();
    }, [])

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, matchPwd])

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        // if button enabled with JS hack
        // const v1 = USER_REGEX.test(user);
        const v1 = true;
        const v2 = PWD_REGEX.test(pwd);
        if (!v1 || !v2) {
            setErrMsg("Invalid Entry");
            return;
        }
        try {
            const response = await axios.post(LOGIN_URL,
            JSON.stringify({ UserName: user, Email: user, Password: pwd }),
            {
                headers:{'Content-Type': 'application/json'},
                withCredentials: false
            })

            console.log(response.status);
            setSuccess(true);

            if(response.status === 200){
                navigate(SpecialTeams.Home)
            }

        } catch (err) {
            if(err instanceof Error)
            {
                // if (!err?.cause) {
                //     setErrMsg('No Server Response');
                // } else if (err.cause?.status === 409) {
                //     setErrMsg('Username Taken');
                // } else {
                //     setErrMsg('Registration Failed')
                // }
            }
            errRef.current?.focus();
        }
    }

    return (
      <div style={{display: 'flex', height:'100%'}}>
        
        {/* COLORED PANEL */}
        <div className="colored-panel">
            <div style={{display:'flex', flexDirection:'column', alignItems:'center', marginTop:'8rem'}}>
                <div style={{width:'20%'}}>
                    <img src={logo}
                         alt="Aire Logo"
                         width="100%"
                         />
                </div>
                
                <div style={{width:'50%'}}>
                    <img src={graphic} 
                         alt="SVG Logo Image"
                         width='100%'
                         />
                </div>
                <div>
                    <span style={{color:'white', verticalAlign:'center', fontSize:'10pt'}}>Aire <FaRegCopyright style={{height:'10px'}}/>2023</span>
                </div>
            </div>
            
        </div>

        {/* LOGIN FORM PANEL */}
        <div style={{flex:1, backgroundColor:Colors.Theme.Shade0, display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            
            <div className="login-form-container" style={{marginTop:'12%'}}>
                <h1 style={{fontSize:'42pt', fontFamily:'Raleway', color:Colors.Theme.Neutral700, textAlign:'center', margin:'0px', lineHeight:'1'}}>Sign Up</h1>
                        
                <form onSubmit={handleSubmit}>
                    <div className="stack-container marg-top-l">
                        {/* EMAIL ADDRESS */}
                        <label className="login-field-label" htmlFor="username">
                            Email
                            <FaCheck className={validPwd ? "valid" : "hide"} />
                            <FaTimes className={validPwd || !user ? "hide" : "invalid"} />
                        </label>
                        <div className="login-field-container">
                            <input className="login-field"
                                type="text"
                                id="username"
                                ref={userRef}
                                autoComplete="off"
                                onChange={(e) => setUser(e.target.value)}
                                value={user}
                                required
                                aria-invalid={validName ? "false" : "true"}
                                aria-describedby="uidnote"
                                onFocus={() => setUserFocus(true)}
                                onBlur={() => setUserFocus(false)}
                                />
                                <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                                    <FaInfoCircle />
                                    4 to 24 characters.
                                    Must begin with a letter. <br/>
                                    Letters, numbers, underscores, hyphens allowed.
                                </p>
                        </div>
        
                        {/* PASSWORD */}
                        <label className="login-field-label" htmlFor="password">
                            Password
                            <FaCheck className={validPwd ? "valid" : "hide"} />
                            <FaTimes className={validPwd || !pwd ? "hide" : "invalid"} />
                        </label>
                        <div className="login-field-container" >
                            <input className="login-field"
                                type="password"
                                id="password"
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                required
                                aria-invalid={validPwd ? "false" : "true"}
                                aria-describedby="pwdnote"
                                onFocus={() => setPwdFocus(true)}
                                onBlur={() => setPwdFocus(false)}
                            />
                            <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                                <FaInfoCircle />
                                8 to 24 characters.<br />
                                Must include uppercase and lowercase letters, a number and a special character.<br />
                                Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                            </p>

                        </div>

                        {/* CONFIRM PASSWORD */}
                        <label className="login-field-label" htmlFor="confirm_pwd">
                            Confirm Password
                            <FaCheck className={validMatch && matchPwd ? "valid" : "hide"} />
                            <FaTimes className={validMatch || !matchPwd ? "hide" : "invalid"} />
                        </label>
                        <div className="login-field-container" >
                            <input className="login-field"
                                    type="password"
                                    id="confirm_pwd"
                                    onChange={(e) => setMatchPwd(e.target.value)}
                                    value={matchPwd}
                                    required
                                    aria-invalid={validMatch ? "false" : "true"}
                                    aria-describedby="confirmnote"
                                    onFocus={() => setMatchFocus(true)}
                                    onBlur={() => setMatchFocus(false)}
                                    />
                            <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                                <FaInfoCircle /> Must match the first password input field.
                            </p>
                        </div>
        
                        {/* <button disabled={!validName || !validPwd || !validMatch ? true : false}>Sign Up</button> */}
                        <div className="marg-top-l" style={{display:'flex', alignItems: 'stretch'}}>
                            <button className="login-button h-fill" style={{marginRight:'10px'}}>
                                Register
                            </button>
                            <button className="cancel-button h-fill" 
                                    style={{marginLeft:'10px'}}
                                    onClick={() => { navigate("/")}}>
                                Cancel
                            </button>
                        </div>

                    </div>
                </form>
                
            </div>
        </div>
      </div>
    )
}

export default Register

