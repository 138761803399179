export const Theme = {
    
    //Neutrals
    Neutral50:'#f8fafc',
    Neutral100:'#f1f5f9',
    Neutral200:'#e2e8fd',
    Neutral300:'#cbd5e1',
    Neutral400:'#9aa3b8',
    Neutral500:'#647489',
    Neutral600:'#475569',
    Neutral700:'#334155',
    Neutral800:'#1e293b',
    Neutral900:'#0f172a',

    //Shade
    Shade0:'#ffffff',
    Shade100:'#000000',

    //Primary
    Primary50:'#eff6ff',
    Primary300:'#93c5fd',
    Primary500:'#3b82f6',
    Primary700:'#1d4ed8',
    Primary900:'#0a2683',

    //Secondary
    Secondary50:'#f7f1d2',
    Secondary300:'#fde296',
    Secondary500:'#fed656',
    Secondary700:'#fec705',
    Secondary900:'#eab202',

    //Success
    Success50:'#ecfdf5',
    Success300:'#6ee7b7',
    Success500:'#10b981',
    Success700:'#047857',
    Success900:'#064e3b',

    //Warning
    Warning50:'#fffbeb',
    Warning300:'#fcd34d',
    Warning500:'#f59e0b',
    Warning700:'#b45309',
    Warning900:'#78350f',

    //Error
    Error50:'#fef2f2',
    Error300:'#ff949b',
    Error500:'#ef4444',
    Error700:'#b91c1c',
    Error900:'#7f1d1d',

}