// const spinner = require('../../assets/infinite-doge.gif')
const spinner = require('../../common/assets/loading/pika.gif')

function Spinner() {
  return (
    <div style={{textAlign:'center'}}>
      <img width={200}
           src={spinner}
           alt="Loading..."
           />
      <div>
        <h1 style={{fontSize:'24px', color:'#6f6f6f'}}>Working...</h1>    
      </div>
    </div>
  )
}

export default Spinner