import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import { Layout, Switch } from 'antd';
import * as Paths from '../common/routes';
import RouterLayout from './RouterLayout';
import Home from '../pages/special-teams/Home';
import Job from '../pages/special-teams/Job';
import PunchList from '../pages/special-teams/PunchList';
import System from '../pages/special-teams/System';
import Unit from '../pages/special-teams/Unit';
import Login from './Login';
import Register from './Register';
import NotFound from './NotFound';
import Unauthorized from './Unauthorized';
import LostPassword from './LostPassword';
import VerifyPassword from './VerifyPassword';
import ResetPassword from './ResetPassword';
import RequireAuth from './RequireAuth';
import PersistLogin from './PersistLogin';
import { layoutStyle } from '../common/styles';
import { useTypedSelector } from '../common/hooks/useTypedSelector';
import "antd/dist/antd.min.css";

const { Content, Header } = Layout;

function App() {

  const { online, busy } = useTypedSelector((state) => state.offline);
  console.log('online status: ' + online);
  return (
        <Layout style={layoutStyle}>
          <Header style={{backgroundColor:'#dfdfdf'}}>
            <Switch checked={online}
              checkedChildren={'ONLINE'}
              unCheckedChildren={'OFFLINE'}
              
            />
          </Header>
          <Content>
            <Routes>
              <Route path="/" element={<RouterLayout/>}>

                {/* SHARED/PUBLIC */}
                {/* <Route index element={<Login />}/> */}
                <Route path={Paths.Shared.Login} element={<Login />}/>
                <Route path={Paths.Shared.Register} element={<Register />}/>
                <Route path={Paths.Shared.Unauthorized} element={<Unauthorized />}/>
                <Route path={Paths.Shared.LostPassword} element={<LostPassword />}/>
                <Route path={Paths.Shared.VerifyPassword} element={<VerifyPassword />}/>

                {/* PROTECTED ROUTES */}
                {/* <Route element={<PersistLogin/>}> */}
                  <Route element={<RequireAuth />}>
                    <Route path={Paths.Shared.ResetPassword} element={<ResetPassword />}/>
                    
                    {/* SPECIAL TEAMS */}
                    <Route path='/' element={<Home />}/>
                    <Route path={Paths.SpecialTeams.Job} element={<Job />}/>
                    <Route path={Paths.SpecialTeams.PunchList} element={<PunchList />}/>
                    <Route path={Paths.SpecialTeams.System} element={<System />}/>
                    <Route path={Paths.SpecialTeams.Unit} element={<Unit />}/>
                  
                  </Route>

                {/* CATCH-ALL */}
                <Route path='/*' element={<NotFound />}/>

                </Route>
            </Routes>
           </Content>
         </Layout>

  );
}

export default App;
