import { ScheduleUnit } from '../../common/enums'
import { IEquipmentHeader } from '../../state/interfaces'
import GRDForm from './forms/GRDForm';

function StartupForm({header} : {header:IEquipmentHeader}) {
    switch(header.type){
      case ScheduleUnit.GRD:
        return (
          <GRDForm unit={header.scheduleUnit}/>
        );
      default:
        return (<div>No Form</div>);
    }
}

export default StartupForm