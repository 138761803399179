import { useTypedSelector } from '../../common/hooks/useTypedSelector';
import { useActions } from '../../common/hooks/useActions';
import { SpecialTeams } from '../../common/routes';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Breadcrumb } from 'antd';
import EquipmentSearchResults from '../../components/special-teams/EquipmentSearchResults';
import JobOverview from '../../components/special-teams/JobOverview';
import { layoutStyle, siderStyle, LayoutValues, contentStyle } from '../../common/styles';

const logo = require('../../common/assets/static/lightLogoTransparent.png');
const { Content, Sider } = Layout;

function Job() {
  
  const { selectedJob } = useTypedSelector((state) => state.jobs.activeJobs);
  const { fetchJobEquipment } = useActions();

  useEffect(() => {
    
    fetchJobEquipment(selectedJob!.number);
  }, 
  [selectedJob])

  const onBack = () => {

}

  
  
return (
    <div>
      <Layout style={layoutStyle}>
          <Sider width={LayoutValues.SiderWidth} style={siderStyle}>
            <div style={{height:'100%',
                         width:'100%', 
                         borderRight:'2px solid #cfcfcf',
                         boxShadow:'0px 0px 10px #bfbfbf',
                         display:'flex',
                         flexDirection:'column',
                         alignItems:'center',
                         }}>
              <img src={logo} style={{width:'40px', marginTop:'15px'}} />
            </div>
          </Sider>
        <Layout>
          
          <Content style={contentStyle}>
              <div className="job-page-breadcrumb-container">
                <a style={{color:'#afafaf'}}>Home  &gt; </a>
                <a style={{color:'#afafaf'}}>Selected Job &gt; </a>
                <a style={{color:'#6f6f6f'}}>{selectedJob!.number}</a>
              </div>
              <JobOverview />  
              <EquipmentSearchResults />
          </Content>
        </Layout>
      </Layout>  
    </div>
  )
}

export default Job
