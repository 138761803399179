import { Form, Input, Button, } from 'antd';
import  Store  from 'antd/lib/form';
import { useEffect, useState } from 'react';
import "./startup-forms.css";
import "../../../common/styles/inputs.css";

const layout = {
    labelCol: { offset: 0,  span: 24 },
    wrapperCol: { span: 24 },
    layout:'vertical'
  };

  const tailLayout = {
    wrapperCol: { offset: 0, span: 24 },
  };



function GRDForm({unit} : {unit:any}) {
  
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [unit])


    const onClick = () => {
        const designCFM = form.getFieldValue('designCfm');
        window.alert(designCFM);
    }

    const initializeValues = (unit:any) : any => {
        return({
            designCfm: unit.DesignCFM,
            rectangularFaceSize: unit.RectangularFaceSize,
            rectangularNeckSize: unit.RectangularNeckSize,
            circularFaceSize: unit.CircularFaceSize,
            circularNeckSize: unit.CircularNeckSize,
        })
    } 
    
  
    return (
        <div className="startup-form-container">

            <Button onClick={onClick} style={{backgroundColor:'dodgerblue', color:'white'}}>Display</Button>
            
            <Form form={form} 
                  name="grd-form"
                  initialValues={initializeValues(unit)}
                  layout="vertical"
                  >
                
                {/* DESIGN CFM */}
                <Form.Item name="designCfm" 
                            label={<label>Design CFM</label>}
                            rules={[{ required: false }]}
                            >
                    <Input className="basic-input"
                           placeholder='Design CFM'
                           readOnly
                           value={unit.DesignCFM}
                           />
                </Form.Item>
    
                {/* INITIAL/OBSERVED CFM */}
                <Form.Item name="observedCfm" 
                            label={<label>Observed CFM</label>}
                            rules={[{ required: false }]}
                            >
                    <Input className="basic-input" placeholder='Observed CFM'/>
                </Form.Item>
    
                {/* FINAL CFM */}
                <Form.Item name="finalCfm" 
                            label={<label>Final CFM</label>}
                            rules={[{ required: false }]}
                            >
                    <Input className="basic-input" placeholder='Final CFM'/>
                </Form.Item>
    
                {/* PERCENTAGE */}
                <Form.Item name="balancePercent" 
                            label={<label>%</label>}
                            rules={[{ required: false }]}
                            >
                    <Input className="basic-input" placeholder='%'/>
                </Form.Item>
    
                {unit.Accessories != null && 
                <Form.Item name="accessory" 
                            label={<label>Accessory</label>}
                            rules={[{ required: false }]}
                            >
                    <Input className="basic-input" placeholder='Accessory'/>
                </Form.Item>
                }
    
                {unit.Mounting != null &&
                <Form.Item name="mounting" 
                           label={<label>Mounting</label>}
                           rules={[{ required: false }]}
                           >
                    <Input className="basic-input" placeholder='Mounting'/>
                </Form.Item>
                }
    
                {unit.RectangularFaceSize != null && 
                <Form.Item name="rectangularFaceSize" 
                           label={<label>Face Size</label>}
                           rules={[{ required: false }]}
                           >
                    <Input className="basic-input" placeholder='Face Size'/>
                </Form.Item>
                }
    
                {unit.RectangularNeckSize != null && 
                <Form.Item name="rectangularNeckSize" 
                           label={<label>Neck Size</label>}
                           rules={[{ required: false }]}
                           >
                    <Input className="basic-input" placeholder='Neck Size'/>
                </Form.Item>
                }
    
                {unit.CircularFaceSize != null && 
                <Form.Item name="circularFaceSize" 
                           label={<label>Face Size</label>}
                           rules={[{ required: false }]}
                           >
                    <Input className="basic-input" placeholder='Face Size'/>
                </Form.Item>
                }
    
                {unit.CircularNeckSize != null && 
                <Form.Item name="circularNeckSize" 
                           label={<label>Neck Size</label>}
                           rules={[{ required: false }]}
                           >
                    <Input className="basic-input" placeholder='Neck Size'/>
                </Form.Item>
                }
    
            </Form>
        </div>
  )
}

export default GRDForm