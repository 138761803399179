import { combineReducers } from 'redux';
import jobsReducer from './jobsReducer';
import commissioningReducer from './commissioningReducer';
import configsReducer from './configsReducer';
import teamReducer from './teamReducer';

const reducers = combineReducers({
    jobs:jobsReducer,
    commissioning:commissioningReducer,
    configs:configsReducer,
    team:teamReducer,
});

export default reducers;

//the line below is to notify Typescript of the root state types. this becomes 
//relevant when using the "useSelector" hook to fetch state inside a functional component.
export type RootState = ReturnType<typeof reducers>;