import { Dispatch } from 'redux';
import { JobActionTypes } from '../action-types';
import { JobAPI } from "../api";
import { JobAction as Action } from '../actions'
import { IJobBudgetProgress, IJobCodeListItem, ITaskCompletionProgress } from '../interfaces';
import { HealthStatus } from '../../common/enums';

const getHealthStatus = (id : number) : HealthStatus => {
    if(id % 2 === 0){
        return HealthStatus.NeedsAttention;
    }
    if(id % 3 === 0){
        return HealthStatus.AtRisk;
        
    }else if(id % 5 === 0){
        return HealthStatus.Good;
    }else{
        return HealthStatus.NoInformation;
    }
}

const getTasksComplete = () : ITaskCompletionProgress => {
    
    const totalTasks = Math.floor(Math.random() * 95) + 1;
    const completedTasks = Math.floor(Math.random() * totalTasks) + 1;
    const percent = Math.round(100*(completedTasks/totalTasks));

    return {
        completedTasks: completedTasks,
        totalTasks: totalTasks,
        percentComplete: percent,
    }
}

const getSampleBudget = (health : HealthStatus) : IJobBudgetProgress => {

    //needs attention
    if(health === HealthStatus.NeedsAttention){
        const budgetedHours = 85;
        const usedHours = 90;
        const percent = Math.round(100*(usedHours/budgetedHours));
        return {
            budgetedHours: budgetedHours,
            usedHours: usedHours,
            percentComplete:percent,
        };   
    }
    //at risk
    if(health === HealthStatus.AtRisk){
        const budgetedHours = 100;
        const usedHours = 80;
        const percent = Math.round(100*(usedHours/budgetedHours));
        return {
            budgetedHours: budgetedHours,
            usedHours: usedHours,
            percentComplete:percent,
        };
        //good.
    }else if(health === HealthStatus.Good){
        const budgetedHours = 120;
        const usedHours = 50;
        const percent = Math.round(100*(usedHours/budgetedHours));
        return {
            budgetedHours: budgetedHours,
            usedHours: usedHours,
            percentComplete:percent,
        }; 
    }else{
        return {
            budgetedHours: 0,
            usedHours: 0,
            percentComplete: 0,
        };
    }
}

export const fetchActiveJobs = (term: string) => {
    return async (dispatch:Dispatch<Action>) =>{
        
        dispatch({
            type:JobActionTypes.SEARCH_ACTIVE_JOBS
        });

        try {
            
            //query the data store
            // const { data } = await axios.get('/jobcodes', {});
            const { data } = await JobAPI.getAll();
            
            //unpack the results
            const viewmodels:IJobCodeListItem[] = data.JobCodesList.map((result: any) => {
                
                const health = getHealthStatus(result.Id);
                const budgetItem = getSampleBudget(health);
                const taskCompletion = getTasksComplete();

                const jobItem = {
                    id:result.Id,
                    name:result.JobName,
                    number:result.JobNumber, 
                    collector:result.JobName.toLowerCase() + result.JobNumber.toLowerCase(),
                    timeLeft:'5 months',
                    health:health,
                    budgetProgress: budgetItem,
                    taskCompletion: taskCompletion
                };

                return jobItem;
            })

            const searchTerm = term;
            const undefinedBudgets = viewmodels.filter((job : IJobCodeListItem) => { return job.budgetProgress!.percentComplete === undefined })           
            const filteredJobs = viewmodels.filter((job : IJobCodeListItem) => { return job.collector.includes(searchTerm)})
            //notify Redux by sending the appropriate dispatch.
            dispatch({
                type:JobActionTypes.SEARCH_ACTIVE_JOBS_SUCCESS,
                payload:filteredJobs
            });

        } 
        catch (err) {
            if(err instanceof Error)
            {
                dispatch({
                type:JobActionTypes.SEARCH_ACTIVE_JOBS_ERROR,
                payload: err.message
               })
            }
        }
    }
}

export const setSelectedJob = (id: number) =>{
    return (dispatch:Dispatch<Action>) =>{
        dispatch({
            type:JobActionTypes.SET_SELECTED_JOB,
            payload:id
        });
    }
}