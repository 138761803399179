import { IEquipmentHeader } from "../../state/interfaces"
import TextDisplay from "../shared/TextDisplay";
import Spinner from "../shared/Spinner";
import { Form, Input, Button, Switch, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useActions } from '../../common/hooks/useActions';
import { useTypedSelector } from "../../common/hooks/useTypedSelector";
import "../styles/unit-startup-info.css";

const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
    // layout:'vertical'
  };
  const tailLayout = {
    wrapperCol: { offset: 0, span: 24 },
  };


function UnitStartupInfo({equipmentHeader} : {equipmentHeader:IEquipmentHeader}) {
  
    const { saveStartupInfo } = useActions();
    const { isLoading } = useTypedSelector((state) => state.commissioning.equipment);

    const [form] = Form.useForm();
    const [isEnabled, setIsEnabled] = useState<boolean>(false);
    const [formConfig, setFormConfig] = useState<any>({});

    useEffect(() => {
        setFormConfig(equipmentHeader.startupConfig);
        loadForm();
      }, [equipmentHeader])

    const undoChanges = () => {
        if(window.confirm('This will undo all changes since the last save. Are you sure? ')){
            loadForm();
            setIsEnabled(false);
        }
    }

    const loadForm = () => {
        const s = equipmentHeader.startupInfo!;
        form.setFieldsValue({
            serialNumber:s.serialNumber,
            supplyAirTempHeating:s.supplyAirTempHeating,
            supplyAirTempCooling:s.supplyAirTempCooling,
            returnAirTempHeating:s.returnAirTempHeating,
            returnAirTempCooling:s.returnAirTempCooling,
            lineSetLength:s.lineSetLength,
            additionalChargeAdded:s.additionalChargeAdded,
            washableFilter:s.washableFilter,
            filterSize:s.filterSize,
            filterQty:s.filterQty,
            thermostatType:s.thermostatType,
            ratedVoltage:s.ratedVoltage,
            actualVoltage:s.actualVoltage,
            supplyAirFanAmps:s.supplyAirFanAmps,
            hasBelts:s.hasBelts,
            beltQty:s.beltQty,
            economizer:s.economizer,
            economizerMinOutsideAirSetting:s.economizerMinOutsideAirSetting,
            suctionPressureHeating:s.suctionPressureHeating,
            suctionPressureCooling:s.suctionPressureCooling,
            dischargePressureHeating:s.dischargePressureHeating,
            dischargePressureCooling:s.dischargePressureCooling,
            outsideAirTemp:s.outsideAirTemp,
            condenserFanAmps:s.condenserFanAmps,
            compressorAmps:s.compressorAmps,
            defrostTimerSet:s.defrostTimerSet,
            oneHundredPercentOutsideAir:s.oneHundredPercentOutsideAir,
            gasPressureSet:s.gasPressureSet,
            vfdServes:s.vfdServes,
            fanMotorFLA:s.fanMotorFLA,
            frequencySetting_Hz:s.frequencySetting_Hz,
            finalFLA:s.finalFLA,
            ratedAmps:s.ratedAmps,
            actualAmps:s.actualAmps,
            outsideAirCFM:s.outsideAirCFM,
            exhaustFanAmps:s.exhaustFanAmps,
            controllerType:s.controllerType,
            returnAirTemp:s.returnAirTemp,
            supplyAirTemp:s.supplyAirTemp,
            fanType:s.fanType,
        })
    }  

    const onSave = () =>{
        
        const startupInfo = {
            id:equipmentHeader.startupInfo!.id,
            equipmentHeaderId:equipmentHeader.id,
            startupInfoConfigId:equipmentHeader.startupInfo!.startupInfoConfigId,
            serialNumber:form.getFieldValue('serialNumber'),
            supplyAirTempHeating:form.getFieldValue('supplyAirTempHeating'),
            returnAirTempHeating:form.getFieldValue('returnAirTempHeating'),
            supplyAirTempCooling:form.getFieldValue('supplyAirTempCooling'),
            returnAirTempCooling:form.getFieldValue('returnAirTempCooling'),
            lineSetLength:form.getFieldValue('lineSetLength'),
            additionalChargeAdded:form.getFieldValue('additionalChargeAdded'),
            washableFilter:form.getFieldValue('washableFilter'),
            filterSize:form.getFieldValue('filterSize'),
            filterQty:form.getFieldValue('filterQty'),
            thermostatType:form.getFieldValue('thermostatType'),
            ratedVoltage:form.getFieldValue('ratedVoltage'),
            actualVoltage:form.getFieldValue('actualVoltage'),
            supplyAirFanAmps:form.getFieldValue('supplyAirFanAmps'),
            hasBelts:form.getFieldValue('hasBelts'),
            beltQty:form.getFieldValue('beltQty'),
            economizer:form.getFieldValue('economizer'),
            economizerMinOutsideAirSetting:form.getFieldValue('economizerMinOutsideAirSetting'),
            suctionPressureHeating:form.getFieldValue('suctionPressureHeating'),
            suctionPressureCooling:form.getFieldValue('suctionPressureCooling'),
            dischargePressureHeating:form.getFieldValue('dischargePressureHeating'),
            dischargePressureCooling:form.getFieldValue('dischargePressureCooling'),
            outsideAirTemp:form.getFieldValue('outsideAirTemp'),
            condenserFanAmps:form.getFieldValue('condenserFanAmps'),
            compressorAmps:form.getFieldValue('compressorAmps'),
            defrostTimerSet:form.getFieldValue('defrostTimerSet'),
            oneHundredPercentOutsideAir:form.getFieldValue('oneHundredPercentOutsideAir'),
            gasPressureSet:form.getFieldValue('gasPressureSet'),
            vfdServes:form.getFieldValue('vfdServes'),
            fanMotorFLA:form.getFieldValue('fanMotorFLA'),
            frequencySetting_Hz:form.getFieldValue('frequencySetting_Hz'),
            finalFLA:form.getFieldValue('finalFLA'),
            ratedAmps:form.getFieldValue('ratedAmps'),
            actualAmps:form.getFieldValue('actualAmps'),
            outsideAirCFM:form.getFieldValue('outsideAirCFM'),
            exhaustFanAmps:form.getFieldValue('exhaustFanAmps'),
            controllerType:form.getFieldValue('controllerType'),
            returnAirTemp:form.getFieldValue('returnAirTemp'),
            supplyAirTemp:form.getFieldValue('supplyAirTemp'),
            fanType:form.getFieldValue('fanType'),
        }
        
        saveStartupInfo({updatedStartupInfo:startupInfo, equipmentHeader:equipmentHeader});

        setIsEnabled(false);
    }
  
    return (

    <div>
        <div className="startup-header-info-container">
            <h1>Equipment Information</h1>
            <div style={{display:'flex', justifyContent:'flex-start'}}>
                <TextDisplay props={{title:'Tag', text:`${equipmentHeader.tag}`}} />
                <TextDisplay props={{title:'Manufacturer', text:`${equipmentHeader.mfr}`}} />
                <TextDisplay props={{title:'Model', text:`${equipmentHeader.model}`}} />
                <TextDisplay props={{title:'Location', text:`${equipmentHeader.location}`}} />
            </div>
        </div>
        <div >
            <h1>Startup Information</h1>
            <Button type='primary' onClick={onSave} disabled={!isEnabled}>Save</Button>
            <Button type='primary' onClick={undoChanges} disabled={!isEnabled}>Undo Changes</Button>
            <div className="startup-info-form-container">
                { isLoading && <Spinner/> }
                { isLoading === false &&
                    <Form {...layout} layout='vertical' form={form} name="startup-info" onChange={() => {if(isEnabled === false){ setIsEnabled(true)}}}>
                    
                    {/* SERIAL NUMBER */}
                    <div>
                        <Form.Item name="serialNumber" 
                                    label={<label>Serial Number</label>}
                                    >
                            <Input placeholder='Serial Number' 
                                    className='startup-form-field'
                                />
                        </Form.Item> 
                    </div>

                    {/* SUPPLY AIR TEMP HEATING */}
                    <div>
                        <Form.Item name="supplyAirTempHeating" 
                                    label={<label>Supply Air Temp (Heating)</label>}
                                    hidden={!formConfig.SupplyAirTemp_Heating}
                                    >
                            <Input placeholder='Supply Air Temp (Heating)' 
                                    className='startup-form-field'
                                    />
                        </Form.Item> 
                    </div>

                    {/* SUPPLY AIR TEMP COOLING */}
                    <div>
                        <Form.Item name="supplyAirTempCooling" 
                                    label={<label>Supply Air Temp (Cooling)</label>}
                                    hidden={!formConfig.SupplyAirTemp_Cooling}
                                    >
                            <Input placeholder='Supply Air Temp (Cooling)'
                                    className='startup-form-field'
                                />
                        </Form.Item> 
                    </div>

                    {/* RETURN AIR TEMP HEATING */}
                    <div>
                        <Form.Item name="returnAirTempHeating" 
                                    label={<label>Return Air Temp (Heating)</label>}
                                    hidden={!formConfig.ReturnAirTemp_Heating}
                                    >
                            <Input placeholder='Return Air Temp (Heating)' 
                                    className='startup-form-field'
                                />
                        </Form.Item>
                    </div> 

                    {/* RETURN AIR TEMP COOLING */}
                    <div>
                        <Form.Item name="returnAirTempCooling" 
                                    label={<label>Return Air Temp (Cooling)</label>}
                                    hidden={!formConfig.ReturnAirTemp_Cooling}
                                    >
                            <Input placeholder='Return Air Temp (Cooling)'
                                    className='startup-form-field'
                                />
                        </Form.Item>
                    </div>

                    {/* LINE SET LENGTH */}
                    <div>
                        <Form.Item name="lineSetLength" 
                                    label={<label>Line Set Length</label>}
                                    hidden={!formConfig.LineSetLength}
                                    >
                            <Input placeholder='Line Set Length (ft)' 
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* ADDITIONAL CHARGE ADDED */}
                    <div>
                        <Form.Item name="additionalChargeAdded" 
                                    label={<label>Additional Charge Added?</label>}
                                    hidden={!formConfig.AdditionalChargeAdded}
                                    valuePropName="checked"
                                    >
                            <Switch checkedChildren={<CheckOutlined/>} 
                                    unCheckedChildren={<CloseOutlined/>}
                                    />
                        </Form.Item>
                    </div>

                    {/* WASHABLE FILTER */}
                    <div>
                        <Form.Item name="washableFilter" 
                                    label={<label>Washable Filter?</label>}
                                    hidden={!formConfig.WashableFilter}
                                    valuePropName="checked"
                                    >
                            <Switch checkedChildren='Yes' 
                                    unCheckedChildren='No'
                                    />
                        </Form.Item>
                    </div>

                    {/* FILTER SIZE */}
                    <div>
                        <Form.Item name="filterSize" 
                                    label={<label>Filter Size</label>}
                                    hidden={!formConfig.FilterSize}
                                    >
                            <Input placeholder='Filter Size'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* FILTER QTY */}
                    <div>
                        <Form.Item name="filterQty" 
                                    label={<label>Filter Qty</label>}
                                    hidden={!formConfig.FilterQty}
                                    >
                            <Input placeholder='Filter Qty'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* THERMOSTAT TYPE */}
                    <div>
                        <Form.Item name="thermostatType" 
                                    label={<label>Thermostat Type</label>}
                                    hidden={!formConfig.ThermostatType}
                                    >
                            <Input placeholder='Thermostat Type'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* RATED VOLTAGE */}
                    <div>
                        <Form.Item name="ratedVoltage" 
                                    label={<label>Rated Voltage</label>}
                                    hidden={!formConfig.RatedVoltage}
                                    >
                            <Input placeholder='Rated Voltage'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* ACTUAL VOLTAGE */}
                    <div>
                        <Form.Item name="actualVoltage" 
                                    label={<label>Actual Voltage</label>}
                                    hidden={!formConfig.ActualVoltage}
                                    >
                            <Input placeholder='Actual Voltage'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* SUPPLY AIR FAN AMPS */}
                    <div>
                        <Form.Item name="supplyAirFanAmps" 
                                    label={<label>Supply Air Fan Amps</label>}
                                    hidden={!formConfig.SupplyAirFanAmps}
                                    >
                            <Input placeholder='Supply Air Fan Amps'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* HAS BELTS */}
                    <div>
                        <Form.Item name="hasBelts" 
                                    label={<label>Has Belts?</label>}
                                    hidden={!formConfig.HasBelts}
                                    valuePropName="checked"
                                    >
                            <Switch checkedChildren='Yes' 
                                    unCheckedChildren='No'
                                    />
                        </Form.Item>
                    </div>

                    {/* BELT QTY */}
                    <div>
                        <Form.Item name="beltQty" 
                                    label={<label>Belt Qty</label>}
                                    hidden={!formConfig.BeltQty}
                                    >
                            <Input placeholder='Belt Qty'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* ECONOMIZER? */}
                    <div>
                        <Form.Item name="economizer" 
                                    label={<label>Economizer?</label>}
                                    hidden={!formConfig.Economizer}
                                    valuePropName="checked"
                                    >
                            <Switch checkedChildren='Yes' 
                                    unCheckedChildren='No'
                                    />
                        </Form.Item>
                    </div>

                    {/* ECONOMIZER MIN OSA SETTING */}
                    <div>
                        <Form.Item name="economizerMinOutsideAirSetting" 
                                    label={<label>Economizer Min OSA Setting</label>}
                                    hidden={!formConfig.EconomizerMinOutsideAirSetting}
                                    >
                            <Input placeholder='Min Outside Air Setting'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* SUCTION PRESSURE (HEATING) */}
                    <div>
                        <Form.Item name="suctionPressureHeating" 
                                    label={<label>Suction Pressure (Heating)</label>}
                                    hidden={!formConfig.SuctionPressure_Heating}
                                    >
                            <Input placeholder='Suction Pressure Heating'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* SUCTION PRESSURE (COOLING) */}
                    <div>
                        <Form.Item name="suctionPressureCooling" 
                                    label={<label>Suction Pressure (Cooling)</label>}
                                    hidden={!formConfig.SuctionPressure_Cooling}
                                    >
                            <Input placeholder='Suction Pressure Cooling'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* DISCHARGE PRESSURE (HEATING) */}
                    <div>
                        <Form.Item name="dischargePressureHeating" 
                                    label={<label>Discharge Pressure (Heating)</label>}
                                    hidden={!formConfig.DischargePressure_Heating}
                                    >
                            <Input placeholder='Discharge Pressure Heating'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* DISCHARGE PRESSURE (COOLING) */}
                    <div>
                        <Form.Item name="dischargePressureCooling" 
                                    label={<label>Discharge Pressure (Cooling)</label>}
                                    hidden={!formConfig.DischargePressure_Cooling}
                                    >
                            <Input placeholder='Discharge Pressure Cooling' 
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* OUTSIDE AIR TEMP */}
                    <div>
                        <Form.Item name="outsideAirTemp" 
                                    label={<label>Outside Air Temp</label>}
                                    hidden={!formConfig.OutsideAirTemp}
                                    >
                            <Input placeholder='Outside Air Temp' 
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* CONDENSER FAN AMPS */}
                    <div>
                        <Form.Item name="condenserFanAmps" 
                                    label={<label>Condenser Fan Amps</label>}
                                    hidden={!formConfig.CondenserFanAmps}
                                    >
                            <Input placeholder='Condenser Fan Amps'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* COMPRESSOR FAN AMPS */}
                    <div>
                        <Form.Item name="compressorFanAmps" 
                                    label={<label>Compressor Fan Amps</label>}
                                    hidden={!formConfig.CompressorFanAmps}
                                    >
                            <Input placeholder='Compressor Fan Amps'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* DEFROST TIMER SET? */}
                    <div>
                        <Form.Item name="defrostTimerSet" 
                                    label={<label>Defrost Timer Set?</label>}
                                    hidden={!formConfig.DefrostTimerSet}
                                    valuePropName="checked"
                                    >
                            <Switch checkedChildren='Yes' 
                                    unCheckedChildren='No'
                                    />
                        </Form.Item>
                    </div>

                    {/* ONE HUNDRED PERCENT OUTSIDE AIR? */}
                    <div>
                        <Form.Item name="oneHundredPercentOutsideAir" 
                                    label={<label>100% Outside Air?</label>}
                                    hidden={!formConfig.OneHundredPercentOutsideAir}
                                    valuePropName="checked"
                                    >
                            <Switch checkedChildren='Yes' 
                                    unCheckedChildren='No'
                                    />
                        </Form.Item>
                    </div>

                    {/* GAS PRESSURE SET? */}
                    <div>
                        <Form.Item name="gasPressureSet" 
                                    label={<label>Gas Pressure Set?</label>}
                                    hidden={!formConfig.GasPressureSet}
                                    valuePropName="checked"
                                    >
                            <Switch checkedChildren='Yes' 
                                    unCheckedChildren='No'
                                    />
                        </Form.Item>
                    </div>

                    {/* VFD SERVES */}
                    <div>
                        <Form.Item name="vfdServes" 
                                    label={<label>VFD Serves</label>}
                                    hidden={!formConfig.VFDServes}
                                    >
                            <Input placeholder='VFD Serves'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* FAN MOTOR FLA */}
                    <div>
                        <Form.Item name="fanMotorFLA" 
                                    label={<label>Fan Motor FLA</label>}
                                    hidden={!formConfig.FanMotorFLA}
                                    >
                            <Input placeholder='Fan Motor FLA'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* FREQUENCY SETTING (HZ) */}
                    <div>
                        <Form.Item name="frequencySetting_Hz" 
                                    label={<label>Frequency Setting (Hz)</label>}
                                    hidden={!formConfig.FrequencySetting_Hz}
                                    >
                            <Input placeholder='Frequency Setting (Hz)'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                        {/* FINAL FLA */}
                        <div>
                        <Form.Item name="finalFLA" 
                                    label={<label>Final FLA</label>}
                                    hidden={!formConfig.FinalFLA}
                                    >
                            <Input placeholder='Final FLA'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                        {/* RATED AMPS */}
                        <div>
                        <Form.Item name="ratedAmps" 
                                    label={<label>Rated Amps</label>}
                                    hidden={!formConfig.RatedAmps}
                                    >
                            <Input placeholder='Rated Amps'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                        {/* ACTUAL AMPS */}
                        <div>
                        <Form.Item name="actualAmps" 
                                    label={<label>Actual Amps</label>}
                                    hidden={!formConfig.ActualAmps}
                                    >
                            <Input placeholder='Actual Amps'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* OUTSIDE AIR CFM */}
                    <div>
                        <Form.Item name="outsideAirCFM" 
                                    label={<label>Outside Air CFM</label>}
                                    hidden={!formConfig.OutsideAirCFM}
                                    >
                            <Input placeholder='Outside Air CFM'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* EXHAUST FAN AMPS */}
                    <div>
                        <Form.Item name="exhaustFanAmps" 
                                    label={<label>Exhaust Fan Amps</label>}
                                    hidden={!formConfig.ExhaustFanAmps}
                                    >
                            <Input placeholder='Exhaust Fan Amps'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* CONTROLLER TYPE */}
                    <div>
                        <Form.Item name="controllerType" 
                                    label={<label>Controller Type</label>}
                                    hidden={!formConfig.ControllerType}
                                    >
                            <Input placeholder='Controller Type'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* RETURN AIR TEMP */}
                    <div>
                        <Form.Item name="returnAirTemp" 
                                    label={<label>Return Air Temp</label>}
                                    hidden={!formConfig.ReturnAirTemp}
                                    >
                            <Input placeholder='Return Air Temp'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* SUPPLY AIR TEMP */}
                    <div>
                        <Form.Item name="supplyAirTemp" 
                                    label={<label>Supply Air Temp</label>}
                                    hidden={!formConfig.SupplyAirTemp}
                                    >
                            <Input placeholder='Supply Air Temp'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    {/* FAN TYPE */}
                    <div>
                        <Form.Item name="fanType" 
                                    label={<label>Fan Type</label>}
                                    hidden={!formConfig.FanType}
                                    >
                            <Input placeholder='Fan Type'
                                    className='startup-form-field'
                                    />
                        </Form.Item>
                    </div>

                    </Form>
                }
                
            </div>
        </div>
    </div>
  )
}

export default UnitStartupInfo