export const FontFamilies = {

    Inter:'Inter',
    Lato:'Lato',
    Poppins:'Poppins',
    Raleway:'Raleway'
}

export const FontSizes = {

    Display01:'64px',
    Display02:'58px',

    Header01:'40px',
    Header02:'36px',
    Header03:'32px',
    Header04:'28px',
    Header05:'22px',
    Header06:'16px',
    
    Subheader:'14px',

    Paragraph01:'16px',
    Paragraph02:'14px',
    Paragraph03:'12px',
    
    Caption:'11px',
    Footer:'10px',

    TextButton01:'16px',
    TextButton02:'14px',
    TextButton03:'12px',
    
    TextEntry:'14px',
}

