import { Input } from 'antd';
import React from 'react';

const { Search } = Input;

interface SearchBoxProps {
    onClick: (value:string) => void;
    placeholderText: string;
}

function SearchBox({ onClick, placeholderText } : SearchBoxProps) {
  
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.value.length === 0){
            onClick('');
        }
    }
    return (
    <div style={{display:'flex', flexDirection:'column', justifyContent:'start', alignItems:'start', marginBottom:'20px', marginTop:'20px'}}>
      <h2 style={{color:'#6f6f6', fontWeight:'400', paddingLeft:'5px' }}>Project Equipment</h2>
          <Search
            size="large"
            allowClear
            bordered
            style={{width: '25%'}}
            placeholder={placeholderText}
            onSearch={onClick}
            onChange={handleChange}
           />
      </div>
  )
}

export default SearchBox