export const Jobs = {

  GetAll:'/jobcodes',

}

// public static class JobCodes
//         {
//             public const string Route = Base + "/jobcodes";

//             public const string GetAll = Route; 
//             public const string GetById = Route + "/{id:int}";
//             public const string GetByJobNumber = Route + "/byjobnumber/{jobnumber}";
//             public const string Add = Route;
//             public const string DeleteById = Route + "/{id:int}";
//         }

export const Systems = {
  
  GetSystemHeadersWithUnitsByJobNumber: '/systems/headerswithunitsbyjobnumber/',
}

// public static class Systems
//         {
//             public const string Route = Base + "/systems";

//             public const string GetScheduleUnitsByJobNumber = Route + "/unitsbyjobnumber/{jobnumber}";
//             public const string GetSystemHeadersByJobNumber = Route + "/headersbyjobnumber/{jobnumber}";
//             public const string GetSystemHeadersWithUnitsByJobNumber = Route + "/headerswithunitsbyjobnumber/{jobnumber}";
//             public const string GetSystemHeaderWithUnitsById = Route + "/headerwithunitsbyid/{id:int}";
//             public const string Add = Route;

//             //public const string GetAll = Route;
//             //public const string GetById = Route + "/{id:int}";
//             public const string Update = Route;

//         }

export const AirBalances = {
  UpdateRange:'/airbalances/updaterange',
}

// public static class AirBalances
//         {
//             public const string Route = Base + "/airbalances";

//             public const string UpdateRange = Route + "/updaterange";
//         }

export const Startup = {
  Update:'/startup',
}

// public static class Startup
//         {
//             public const string Route = Base + "/startup";

//             public const string GetByEquipmentHeaderId = Route + "/byequipmentheaderid/{id:int}";
//             public const string FetchStartupConfiguration = Route + "/startupconfiguration";
//             public const string Add = Route;
//             public const string Update = Route; 
            
//         }

export const Team = {
  Login:'/banana/login',
}