import { configureStore } from '@reduxjs/toolkit';
import { offline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import reducers from './reducers';

export const store = configureStore({
  reducer: reducers,
  enhancers: [offline(offlineConfig)]
});

// export const store = createStore(
//   reducers, 
//   composeWithDevTools(applyMiddleware(thunk)),
//   );

// export const store = createStore(
//   reducers, 
//   composeWithDevTools(
//     applyMiddleware(thunk), 
//     offline(offlineConfig)
//     )
//   );
