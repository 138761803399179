import { Dispatch } from 'redux';
import { ConfigsActionTypes as ActionTypes } from '../action-types';
import { ConfigsAction as Action } from '../actions';
import { IFeatureFlag } from '../interfaces';

export const fetchFeatureFlags = () => {
    return async (dispatch:Dispatch<Action>) => {
        dispatch({
            type:ActionTypes.FETCH_FEATURE_FLAGS
        });

        try{

            const featureFlags : IFeatureFlag[] = [
                {
                    name:'system-detail-page',
                    on:true,
                    trafficType:'public',
                    description:'Opens the selected system information in the System page.'
                },
                {
                    name:'system-detail-drawer',
                    on:false,
                    trafficType:'public',
                    description:'Opens the selected system in the side drawer panel.'
                },
                {
                    name:'item-detail-single-panel',
                    on:false,
                    trafficType:'public',
                    description:'User selects from SystemDetailTree to open unit-specific data'
                },
                {
                    name:'item-detail-expanders',
                    on:true,
                    trafficType:'public',
                    description:'System detail information is displayed in expanders associated with system buckets'
                },
                {
                    name:'system-details-tab-first',
                    on:true,
                    trafficType:'public',
                    description:'Shows tab first system detail flow.'
                },
               
            ];

            dispatch({
                type: ActionTypes.FETCH_FEATURE_FLAGS_SUCCESS,
                payload: featureFlags
            });
        }
        catch (err) {
            if(err instanceof Error){
                dispatch({
                    type: ActionTypes.FETCH_FEATURE_FLAGS_ERROR,
                    payload: err.message
                })
            }
        }
    }
}