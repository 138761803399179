import axios from "axios";

export default axios.create({
    baseURL:process.env.REACT_APP_BASE_URL,
    timeout: 1000 * 20,
    withCredentials: process.env.REACT_APP_WITH_CREDENTIALS === "true" ? true : false,
    headers: {
        Accept: "applicaton/json",
        "Content-Type": "application/json",
    },
});

// baseURL: process.env.REACT_APP_BASE_URL,


//BOD
// let axiosInstance: AxiosInstance;

// const axiosBaseConfig = {
//     baseURL:'https://aire-api-dev.azurewebsites.net/api/v1',
//     timeout: 1000 * 20,
//     withCredentials: true,
//     headers: {
//         Accept: "applicaton/json",
//         "Content-Type": "application/json",
//     },
// };
// export const getAxios = () => {
//   if (axiosInstance) {
//     return axiosInstance;
//   }

//   axiosInstance = axios.create(axiosBaseConfig);

//   return axiosInstance;
// };