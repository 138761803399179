import { useActions } from '../../common/hooks/useActions';
import { useTypedSelector } from '../../common/hooks/useTypedSelector';
import { DownOutlined } from '@ant-design/icons';
import { FaChessKing, FaChessPawn } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import GrdServiceTypeIcon from '../special-teams/GrdServiceTypeIcon';
import { Form, Switch, Tree, Tooltip, TreeProps } from 'antd';
import type { DataNode } from 'antd/lib/tree';
import { ScheduleUnit } from '../../common/enums';
import { useState, useEffect } from 'react';
import { IEquipmentHeader } from '../../state/interfaces';
import { Colors } from '../../common/styles';

function SystemDetailTree () {

  const { headers } = useTypedSelector((state) => state.commissioning.equipment.selectedSystem!)
  const { setSelectedEquipmentHeader } = useActions();
  const [ selectedKeys, setSelectedKeys ] = useState<any[]>([]);

  useEffect(() => {
    setSelectedKeys([]);
  }, [headers])


  const populateGRDArray = (grds: IEquipmentHeader[]) : any[] => {
    
    const items = grds.map((u) => u.scheduleUnit);

    return [
      {
        title:'GRD',
        key:'GRD',
        selectable:false,
        icon:<GiHamburgerMenu style={{color:Colors.Primary500}}/>,
        children: grds.map((unit) => { 
                    return {
                      title:(() => {
                        return(
                          <span>{unit.tag}<i>{unit.location === '' || unit.location === null ? ' (No location given)' : ` - ${unit.location}`}</i></span>
                        );
                      }),
                      key:unit.id,
                      selectable:true,
                      icon:<GrdServiceTypeIcon grdServiceType={unit.scheduleUnit!.ServiceType} fontSize={null}/> 
                    }
                  })
      }
    ]
  }

  const populateSecondaryArray = (units: IEquipmentHeader[]) : any[] => {
    return units.length === 0 ? [] :
      units.map((unit:IEquipmentHeader) => {
        return {
          title:unit.tag,
          key:unit.id,
          selectable:true,
          icon:<FaChessPawn style={{color:Colors.Primary500}} />
        }
      })
  }

  const populateSystemTree = () : DataNode[] => {

    const primaries: IEquipmentHeader[] = headers.filter((header) => {
      return header.isPrimary === true
    });
    const grds: IEquipmentHeader[] = headers.filter((header)=> {
       return header.type === ScheduleUnit.GRD
    });
    const secondaries: IEquipmentHeader[] = headers.filter((header) => {
      return header.type !== ScheduleUnit.GRD && header.isPrimary !== true
    })
  
    const systemGrds = grds.length === 0 ? [] : populateGRDArray(grds);
    const systemSecondaries = populateSecondaryArray(secondaries);
    const systemChildren = [...systemSecondaries, ...systemGrds];

    const systemTree = [
      {
        title: primaries.length === 0 ? 'No Primary' : (() => {
          return(
            <h4 style={{margin:'0px'}}>
              <FaChessKing style={{color:Colors.Primary500}}/> {primaries[0].tag} 
              <span style={{fontWeight:'400'}}>
                <i> (Primary)</i>
              </span>
            </h4>
          )
        }),
        key: primaries.length === 0 ? 'No Primary' : primaries[0].id,
        // icon:<FaChessKing style={{color:Colors.Primary500}}/>,
        selectable:true,
        children: systemChildren
        
      }
    ]
  
    return systemTree;
  }

  const onSelect = (selectedKeys:any, info:any) => {
    
    if(selectedKeys.length > 0){
      setSelectedKeys([selectedKeys[0]]);
      setSelectedEquipmentHeader(selectedKeys[0]);
    }
  };

  

  return (
    <div style={{width:'350px', 
                 alignSelf:'flex-start',
                 backgroundColor:'transparent'
                 }}>
      <Tree
        showLine={false}
        showIcon={true}
        defaultExpandAll={true}
        autoExpandParent={true}
        switcherIcon={<DownOutlined />}
        onSelect={onSelect}
        selectedKeys={selectedKeys}
        treeData={populateSystemTree()}
        style={{backgroundColor:Colors.Shade0}}
        />
    </div>
  );
};

export default SystemDetailTree;
// defaultExpandedKeys={['0-0-0']}