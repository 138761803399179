import axios from './axios'
import * as routes from './routes';
import { IUnitAirBalance, IUnitStartupInfo } from '../interfaces';

export interface IUpdateAirBalancesRequest{
  airBalances:IUnitAirBalance[]
}
export interface IUpdateStartupInfoRequest{
  payload:IUnitStartupInfo
}


export const CommissioningAPI = {

  getHeadersWithUnitsByJobNumber: async function(jobNumber:string){
    
    const response = await axios.get(routes.Systems.GetSystemHeadersWithUnitsByJobNumber + jobNumber, {});
    return response;
  },

  updateAirBalances: async function(updateRequest:IUpdateAirBalancesRequest){
    
    const response = await axios.put(routes.AirBalances.UpdateRange, updateRequest);
    return response;
  },

  updateStartupInfo: async function(updateRequest:IUpdateStartupInfoRequest){

    const response = await axios.put(routes.Startup.Update, updateRequest);
    return response;
  }

}