export enum CommissioningActionTypes {
    FETCH_JOB_EQUIPMENT = 'fetch_job_equipment', 
    FETCH_JOB_EQUIPMENT_SUCCESS = 'fetch_job_equipment_success',
    FETCH_JOB_EQUIPMENT_ERROR = 'fetch_job_equipment_error',
    
    SET_SELECTED_SYSTEM = 'set_selected_system',
    SET_SELECTED_EQUIPMENT_HEADER = 'set_selected_equipment_header',
    
    SAVE_AIR_BALANCES = 'save_air_balances',
    SAVE_AIR_BALANCES_SUCCESS = 'save_air_balances_success',
    SAVE_AIR_BALANCES_ERROR = 'save_air_balances_error',

    SAVE_STARTUP_INFO = 'save_startup_info',
    SAVE_STARTUP_INFO_SUCCESS = 'save_startup_info_success',
    SAVE_STARTUP_INFO_ERROR = 'save_startup_info_error',
}

