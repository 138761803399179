import { ConfigsActionTypes } from "../action-types";
import { ConfigsAction } from "../actions";
import { IFeatureFlag } from '../interfaces';

interface IConfigsState{
    features:IFeaturesState;
}

interface IFeaturesState{
    flags:IFeatureFlag[],
    isLoading: boolean;
    error: string | null;
}

//Initial States 
const initialFeaturesState : IFeaturesState = {
    flags: [],
    isLoading: false,
    error: null,
}

const initialState: IConfigsState = {
    features: initialFeaturesState,
}

const reducer = (state: IConfigsState = initialState, action: ConfigsAction) : IConfigsState => {

    switch(action.type){
        case ConfigsActionTypes.FETCH_FEATURE_FLAGS:
            return { features:{
                        isLoading: true,
                        error: null, 
                        flags: [],
                    }};
        case ConfigsActionTypes.FETCH_FEATURE_FLAGS_SUCCESS:
            return { features:{
                        isLoading: false,
                        error: null, 
                        flags: action.payload,
                    }};
        case ConfigsActionTypes.FETCH_FEATURE_FLAGS_ERROR:
            return { features:{
                        isLoading: false,
                        error: action.payload, 
                        flags: [],
                    }};
        default:
            return state;
    }

};

export default reducer;