import { useRef, useState, useEffect } from "react";
import { FaRegCopyright } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';
import { SpecialTeams, Shared } from '../common/routes';

import axios from '../state/api/axios';
import * as Colors from '../common/color-themes';

const graphic = require('../common/assets/static/constructionTransparent.png');
const logo = require('../common/assets/static/lightLogoTransparent.png');
const primary = Colors.Theme.Primary500;
const darkPrimary = Colors.Theme.Primary700

const USER_REGEX = /^[a-zA-Z0-9!@#$&()]{3,23}$/;
const PWD_REGEX = /^[A-z][A-z0-9-_]{7,23}$/;
const LOGIN_URL = 'banana/login';

const VerifyPassword = () => {
    const userRef = useRef<HTMLInputElement>(null);
    const errRef = useRef<HTMLParagraphElement>(null);
    const navigate = useNavigate();

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        userRef.current?.focus();
    }, [])

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, matchPwd])

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        // // if button enabled with JS hack
        // // const v1 = USER_REGEX.test(user);
        // const v1 = true;
        // const v2 = PWD_REGEX.test(pwd);
        // if (!v1 || !v2) {
        //     setErrMsg("Invalid Entry");
        //     return;
        // }
        try {
    
            navigate(Shared.ResetPassword);

        } catch (err) {
            if(err instanceof Error)
            {
                // if (!err?.cause) {
                //     setErrMsg('No Server Response');
                // } else if (err.cause?.status === 409) {
                //     setErrMsg('Username Taken');
                // } else {
                //     setErrMsg('Registration Failed')
                // }
            }
            errRef.current?.focus();
        }
    }

    return (
      <div style={{display: 'flex', height:'100%'}}>
        
        {/* COLORED PANEL */}
        <div className="colored-panel">
            <div style={{display:'flex', flexDirection:'column', alignItems:'center', marginTop:'8rem'}}>
                <div style={{width:'20%'}}>
                    <img src={logo}
                         alt="Aire Logo"
                         width="100%"
                         />
                </div>
                
                <div style={{width:'50%'}}>
                    <img src={graphic} 
                         alt="SVG Logo Image"
                         width='100%'
                         />
                </div>
                <div>
                    <span style={{color:'white', verticalAlign:'center', fontSize:'10pt'}}>Aire <FaRegCopyright style={{height:'10px'}}/>2023</span>
                </div>
            </div>
            
        </div>

        {/* LOGIN FORM PANEL */}
        <div style={{flex:1, backgroundColor:Colors.Theme.Shade0, display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            
            <div className="login-form-container" style={{marginTop:'20%'}}>
                <h1 style={{fontSize:'42pt', fontFamily:'Raleway', color:Colors.Theme.Neutral700, textAlign:'center', margin:'0px', lineHeight:'1'}}>Forgot Password</h1>
                    
                        <div className="marg-top-l" style={{display:'flex', justifyContent:'left', alignItems:'center'}}>
                            <p style={{fontSize:'12pt', margin:'0px 15px 0px 0px'}}> 
                                Please enter the temporary password that <br/>was sent to your email.
                            </p>
                            {/* <Link to={Shared.Register}>Sign Up</Link> */}
                        </div>    
                        
                <form onSubmit={handleSubmit}>
                    <div className="stack-container marg-top-l">
                        {/* EMAIL ADDRESS */}
                        <label className="login-field-label" htmlFor="temp_password">
                            Temporary Password
                        </label>
                        <div className="login-field-container">
                            <input className="login-field"
                                type="text"
                                id="temp_password"
                                ref={userRef}
                                autoComplete="off"
                                onChange={(e) => setUser(e.target.value)}
                                value={user}
                                required
                                aria-invalid={validName ? "false" : "true"}
                                aria-describedby="uidnote"
                                onFocus={() => setUserFocus(true)}
                                onBlur={() => setUserFocus(false)}
                                />
                        </div>
        
                        {/* <button disabled={!validName || !validPwd || !validMatch ? true : false}>Sign Up</button> */}
                        <button className="login-button h-fill marg-top-l">
                            Next
                        </button>

                    </div>
                </form>
                
            </div>
        </div>


      
      </div>
    )
}

export default VerifyPassword

