import { Progress } from 'antd';
import { useEffect } from 'react';
import { Colors, alerts } from '../common/styles';
import { IJobBudgetProgress } from '../state/interfaces'

const strokeWidth = 15;

function BudgetProgressBar({budget} : {budget: IJobBudgetProgress}) {
  
    useEffect(() => {
        logBudget();
      }, [])

    const logBudget = () => {
        if(budget.usedHours === undefined){
            console.log("what the fuck?")
        }
    }
       
    if(budget.percentComplete > 100){
        return (
            <div style={{margin:'0px'}}>
                <p style={{margin:'0px 0px -5px 0px', color:Colors.Secondary700, float:'left'}}><b>{budget.percentComplete}%</b></p>
              <Progress 
                  strokeColor={alerts.Error}
                  type={'line'}
                  strokeWidth={strokeWidth}
                  showInfo={false}
                  strokeLinecap='square'
                  percent={budget.percentComplete}
                  />
                  <p style={{margin:'0px', color: Colors.Neutral600}}>{budget.usedHours}h of {budget.budgetedHours}h</p>
              </div>
        );
    }else if(budget.percentComplete === 100){
        return (
            <div>
                <p style={{margin:'0px 0px -5px 0px', color:Colors.Primary500, float:'left'}}><b>{budget.percentComplete}%</b></p>
              <Progress 
                  strokeColor={alerts.Success}
                  type={'line'}
                  strokeWidth={strokeWidth}
                  status={'success'}
                  showInfo={false}
                  strokeLinecap='square'
                  percent={budget.percentComplete}
                  />
                  <p style={{color: Colors.Neutral600}}>{budget.usedHours}h of {budget.budgetedHours}h</p>
              </div>
              );
    }else if(budget.percentComplete > 75 && budget.percentComplete < 100){
        return (
            <div>
                <p style={{margin:'0px 0px -5px 0px', color:Colors.Primary700, float:'left'}}><b>{budget.percentComplete}%</b></p>
              <Progress 
                  strokeColor={Colors.Primary500}
                  type={'line'}
                  strokeWidth={strokeWidth}
                  strokeLinecap='square'
                  showInfo={false}
                  percent={budget.percentComplete}
                  />
              <p style={{color: Colors.Neutral600}}>{budget.usedHours}h of {budget.budgetedHours}h</p>
              </div>
        );
    }else{  
        return (
            <div>
                <p style={{margin:'0px 0px -5px 0px', color:Colors.Primary700, float:'left'}}><b>{budget.percentComplete}%</b></p>
              <Progress 
                  strokeColor={Colors.Primary500}
                  type={'line'}
                  strokeWidth={strokeWidth}
                  strokeLinecap='square'
                  showInfo={false}
                  percent={budget.percentComplete}
                  />
                  <p style={{color: Colors.Neutral600}}>{budget.usedHours}h of {budget.budgetedHours}h</p>
              </div>
        );
    }
}

export default BudgetProgressBar