import { TeamActionTypes } from '../action-types';
import { TeamAction } from '../actions';
import { IApplicationUser } from '../interfaces';

//State interfaces
interface IUserState{
  data: IApplicationUser | null,
  isLoading: boolean,
  isLoggedIn: boolean,
  error: string | null
}

interface ITeamState{
  user: IUserState
}

//Initial states
const initialUserState = {
  data: null,
  error: null,
  isLoggedIn: false,
  isLoading: false
}

const initialTeamState = {
  user: initialUserState
}


//Reducer
const reducer = (state: ITeamState = initialTeamState, action: TeamAction): ITeamState => {

  switch(action.type)
  {
    case TeamActionTypes.LOGIN_USER:
      return {
        user: {
          data: null,
          error: null,
          isLoading: true,
          isLoggedIn: false         
        }
      };
      
    case TeamActionTypes.LOGIN_USER_SUCCESS:
      return {
        user: {
          data: action.payload,
          error: null,
          isLoading: false,
          isLoggedIn: true,
      }
    };
        
    case TeamActionTypes.LOGIN_USER_ERROR:
      return {
        user: {
          data: null,
          error: action.payload,
          isLoading: false,
          isLoggedIn: false,
        }
      };

    default:
      return state;
  }
};

export default reducer;



