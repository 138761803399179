import { useTypedSelector } from '../../common/hooks/useTypedSelector';
import { useActions } from '../../common/hooks/useActions';
import StartupForm from './StartupForm';
import "../styles/item-detail-panel.css";

function ItemDetailPanel() {
  
  const { selectedEquipmentHeader } = useTypedSelector((state) => state.commissioning.equipment);
  
    return (
      <div className="item-detail-panel-container">
          {selectedEquipmentHeader !== null && 
            <div>
              <div className="item-detail-panel-header">
                <h1>{selectedEquipmentHeader!.tag} {selectedEquipmentHeader!.location}</h1>
                <p>Mfr: {selectedEquipmentHeader!.mfr}</p>
                <p>Model: {selectedEquipmentHeader!.model}</p>
              </div>
              <div>
                <StartupForm header={selectedEquipmentHeader} />
              </div>
            </div>
          }
      </div>
  )
}

export default ItemDetailPanel