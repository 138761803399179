import { Theme } from "../color-themes";

export const Colors = {
    
    Neutral50:Theme.Neutral50,
    Neutral100:Theme.Neutral100,
    Neutral200:Theme.Neutral200,
    Neutral300:Theme.Neutral300,
    Neutral400:Theme.Neutral400,
    Neutral500:Theme.Neutral500,
    Neutral600:Theme.Neutral600,
    Neutral700:Theme.Neutral700,
    Neutral800:Theme.Neutral800,
    Neutral900:Theme.Neutral900,

    Shade0:Theme.Shade0,
    Shade100:Theme.Shade100,

    Primary50:Theme.Primary50,
    Primary300:Theme.Primary300,
    Primary500:Theme.Primary500,
    Primary700:Theme.Primary700,
    Primary900:Theme.Primary900,

    Secondary50:Theme.Secondary50,
    Secondary300:Theme.Secondary300,
    Secondary500:Theme.Secondary500,
    Secondary700:Theme.Secondary700,
    Secondary900:Theme.Secondary900,

    Success50:Theme.Success50,
    Success300:Theme.Success300,
    Success500:Theme.Success500,
    Success700:Theme.Success700,
    Success900:Theme.Success900,

    Warning50:Theme.Warning50,
    Warning300:Theme.Warning300,
    Warning500:Theme.Warning500,
    Warning700:Theme.Warning700,
    Warning900:Theme.Warning900,

    Error50:Theme.Error50,
    Error300:Theme.Error300,
    Error500:Theme.Error500,
    Error700:Theme.Error700,
    Error900:Theme.Error900,

}





