interface TextDisplayProps{
    title:string,
    text:string
}

function TextDisplay({props}:{props:TextDisplayProps}) {
  return (
    <div style={{lineHeight:'1.3', margin:'10px 15px'}}>
        <p style={{fontSize:'14px', color:'#6f6f6f', margin:'0px', padding:'0px'}}>{props.title}</p>
        <p style={{fontSize:'18px', fontWeight:'500', color:'#555', margin:'0px 0px 0px 0px', padding:'0px'}}>{props.text}</p>
    </div>
  )
}

export default TextDisplay