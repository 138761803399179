import { Dispatch } from "redux";
import { TeamActionTypes } from "../action-types";
import { TeamAPI } from "../api";
import { TeamAction as Action } from "../actions";
import { IUserLoginRequest } from "../api/TeamAPI";
import { IApplicationUser } from "../interfaces";

export const loginUser = async (request: IUserLoginRequest, dispatch: Dispatch<Action>): Promise<boolean> => {
  
    return new Promise<boolean>((response) => AttemptLogin(dispatch, request));
};


export const AttemptLogin = async (dispatch:Dispatch<Action>, request: IUserLoginRequest) : Promise<boolean> => 
{
    
  dispatch({
    type: TeamActionTypes.LOGIN_USER,
  });

  try {

    const { data, status, statusText } = await TeamAPI.loginUser(request);

    if (data?.User != null) {
      const appUser = data as IApplicationUser;

      dispatch({
        type: TeamActionTypes.LOGIN_USER_SUCCESS,
        payload: appUser,
      });

      return Promise.resolve(true);
      
    } else {
      dispatch({
        type: TeamActionTypes.LOGIN_USER_ERROR,
        payload: `${status}: ${statusText}`,
      });
      
      return Promise.resolve(true);
    }
  } catch (err) {
    if (err instanceof Error) {
      dispatch({
        type: TeamActionTypes.LOGIN_USER_ERROR,
        payload: err.message,
      });
    }
    
    return Promise.resolve(true);
  }
};
