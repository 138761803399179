import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useTypedSelector } from '../common/hooks/useTypedSelector';
import * as Paths from '../common/routes';


const RequireAuth = () => {
    
    const { data, isLoggedIn } = useTypedSelector((state) => state.team.user);
    const location = useLocation();
    
    return (
        data != null && isLoggedIn 
            ? <Outlet />
            : <Navigate to={ Paths.Shared.Login} state={{ from: location }} replace />
    );
}

export default RequireAuth; 