import { useActions } from '../../common/hooks/useActions';
import { useTypedSelector } from '../../common/hooks/useTypedSelector';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SpecialTeams as Paths } from '../../common/routes';
import { Table, Popover, Tooltip, Button, Progress } from 'antd';
import { FaShareAltSquare } from 'react-icons/fa';
import SystemTree from './SystemTree';
import SearchBox from '../shared/SearchBox';
import Spinner from '../../components/shared/Spinner';
import SystemDetailPanel from '../system-detail-panel/SystemDetailPanel';
import { Colors, buttonStyle } from '../../common/styles';
import { IEquipmentSystem, IEquipmentHeader } from '../../state/interfaces';
import "../styles/equipment-search-results.css"

function EquipmentSearchResults() {

  const { data, error, isLoading, selectedSystem } = useTypedSelector((state) => state.commissioning.equipment)
  const { features } = useTypedSelector((state) => state.configs);
  const { setSelectedSystem, setSelectedEquipmentHeader } = useActions();
  const navigate = useNavigate();

  const systemDetailPage = features.flags.find((f) => f.name === 'system-detail-page'); 
  const systemDetailDrawer = features.flags.find((f) => f.name === 'system-detail-drawer');

  const [ detailPanelVisible, setDetailPanelVisible] = useState<boolean>(false);
  const [ filteredSystemList, setFilteredSystemList ] = useState<IEquipmentSystem[]>(data!);
  const [ screenWidth, setScreenWidth ] = useState<number>(0);

  useEffect(() => {
    setFilteredSystemList(data!);
    setScreenWidth(window.innerWidth);
  }, [data])

  const openSystemDetail = (system:IEquipmentSystem) => {
      
      setSelectedSystem(system.id);
      if(selectedSystem !== null && system.id !== selectedSystem.id){
        setSelectedEquipmentHeader(null);
      }
      if(systemDetailPage!.on){
        navigate(Paths.System);
      }
      if(systemDetailDrawer!.on){
        showDetailPanel();
      }
  }

  const showDetailPanel = () => {
    setDetailPanelVisible(true);
  }
  
  const closeDetailPanel = () => {
    setDetailPanelVisible(false);
  }

  const onSearchList = (value:string) => {

    const searchTerm = value.toLowerCase();
    const filteredList = data.filter((system:IEquipmentSystem) => {
      return system.searchString.toLowerCase().includes(searchTerm);
    })

    setFilteredSystemList(filteredList);
  }

  const columns = [
    {
      title:'Progress',
      dataIndex:'',
      key:'id',
      width:'100px',
      render: (text:string, record:IEquipmentSystem) => {
        return(
          <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <Tooltip title={`${record.progress}%`} color={Colors.Neutral600}>
              <Progress type="circle"
                        strokeWidth={15}
                        trailColor={'white'}
                        strokeColor={record.progress === 100 ? Colors.Success300 : Colors.Primary300}
                        percent={record.progress} 
                        width={40}
              />
            </Tooltip>
          </div>
        )
      }
    },
    {
      title:'System Name',
      dataIndex:'systemName',
      key:'id',
      
    },
    {
      title: 'Primary Tag',
      dataIndex:'tag',
      key:'id',
    },
    {
      title:'Unit Tree',
      key:'id',
      render: (text:string, record:IEquipmentSystem) => {

        return(
          <div>
            <Popover 
                title={record.systemName}
                trigger="click"
                placement={"rightTop"} 
                style={{width:'400px'}} 
                content={<SystemTree units={record.headers} />}
                >
                  <Tooltip title="Click to see System Tree" color={Colors.Neutral600}>
                    <FaShareAltSquare fontSize="24px" color={Colors.Primary500}
                      onMouseOver={(e : React.MouseEvent<SVGElement>) => e.currentTarget.style.color=`${Colors.Primary500}dd`}
                      onMouseOut={(e: React.MouseEvent<SVGElement>) => e.currentTarget.style.color=Colors.Primary500}
                    />
                  </Tooltip>
            </Popover>
          </div>
        )
      }
    },
    {
      title: 'Unit Count',
      dataIndex:'unitCount',
      key:'id',
    },
    {
      title:'',
      key:'id',
      render:(text:string, record:IEquipmentSystem) =>{
        return(
          <Button style={buttonStyle}
                  key={record.id}
                  onClick={e => openSystemDetail(record)}
                  >
            <h4 style={{color:'white'}}>Open</h4>
          </Button>
        );
      }
    }
  ]

  window.onresize = () => {
    setScreenWidth(window.innerWidth)
  }

  return (
    <div>
      {/* <h1>{screenWidth}</h1> */}
      <div className='system-list-container'>
          { error && <h3>{ error }</h3>}
          { isLoading && <Spinner /> }
          { !error && !isLoading && data.length === 0 && 
              <h3>No units are available for this project</h3>
          }
          { !error && !isLoading && 
            <div>
              <SearchBox onClick={onSearchList} placeholderText='Search project equipment by tag or name'/>
              <Table 
                bordered
                scroll={{y:'80vh'}}
                pagination={false}
                columns={columns}
                dataSource={filteredSystemList!}
                rowKey={(record:IEquipmentSystem) => record.id}
                style={{height:'50px'}}
              />
            </div>
          }
          
          <SystemDetailPanel visible={detailPanelVisible} 
                             closePanel={closeDetailPanel} />
      </div>
    </div>
  )
}

export default EquipmentSearchResults