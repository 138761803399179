import Spinner from '../components/shared/Spinner';
import JobHealth from './JobHealth';
import BudgetProgressBar from './BudgetProgressBar';
import TaskProgressBar from './TaskProgressBar';
import { SpecialTeams as Paths, SpecialTeams } from '../common/routes';
import { Table, TableProps, Progress, Tag, Space, Tooltip, Button } from 'antd';
import { StarFilled } from '@ant-design/icons';
import { AiFillClockCircle, AiOutlineClockCircle } from 'react-icons/ai';
import { useActions } from '../common/hooks/useActions';
import { useTypedSelector } from '../common/hooks/useTypedSelector';
import { useNavigate } from 'react-router-dom';
import { IJobBudgetProgress, IJobCodeListItem, ITaskCompletionProgress } from '../state/interfaces';
import { HealthStatus } from '../common/enums';
import { buttonStyle, alerts, fonts, Colors } from '../common/styles';

function JobSearchResults() {
  
  const { data, error, isLoading } = useTypedSelector((state) => state.jobs.activeJobs);
  const navigate = useNavigate();
  const { setSelectedJob } = useActions();

  const handleClick = (jobId : number) => {
    setSelectedJob(jobId);
    navigate(SpecialTeams.Job, {
      state:{
        jobNumber:jobId
      }
    });
  }

  const onChange: TableProps<IJobCodeListItem>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('paramgs', pagination, filters, sorter, extra);
  };

  const columns = [
    {
      title: 'Job Number',
      dataIndex: 'number',
      key: 'number',
      render: (number:string) => {
        return (
          <p style={rowTextStyle}>{number}</p>
        )
      },
      sorter:{
        compare: (a:IJobCodeListItem, b:IJobCodeListItem) => a.number.localeCompare(b.number)
      }
      
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name:string) => {
        return (
          <p style={rowTextStyle}>{name}</p>
        )
      }
    },
    {
      title: 'Time left',
      dataIndex: 'timeLeft',
      key:'timeLeft',
      render: (timeLeft : string) => {
        return (
          <div>
            {/* <StarFilled 
              style={{float:'left', color:Colors.Primary, fontSize:'20px', margin:'0px 10px 0px 0px'}}
            /> */}
            <AiFillClockCircle style={{float:'left', color:'#8a8a8a', fontSize:'20px', margin:'3px 10px 0px 0px'}}/>
            <p style={rowTextStyle}>{timeLeft}</p>
          </div>
        )
      }
    },
    {
      title: 'Task Completion',
      dataIndex: 'taskCompletion',
      key: 'name',
      render: (taskCompletion: ITaskCompletionProgress) => {
        return (
            <TaskProgressBar progress={taskCompletion}/>
        );
      }
    },
    {
      title: 'Budget',
      dataIndex:'budgetProgress',
      key:'budget',
      render: (budget: IJobBudgetProgress) => {
        return (
          <BudgetProgressBar budget={budget}
            />
        )
      }
    },
    {
      title: 'Health',
      dataIndex:'health',
      key:'health',
      render: (health : HealthStatus) => {
        return (
          <JobHealth key={health} health={health}
            />
        )
      }
    },
    {
      title: 'Last Action',
      dataIndex:'lastAction',
      key:'lastAction'
    },
    {
      title:'',
      dataIndex: 'id',
      key: 'id',
      render: (recordId : number) => {
          return (
              <Button
                style={buttonStyle}
                key={recordId}
                onClick={e => handleClick(recordId)}
              >
              <h4 style={{color: 'white'}}>Open</h4>
              </Button>
            );
          }
    },
    
  ]

  return (
    <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        { error && <h3>{ error }</h3>}
        { isLoading && <Spinner /> }
        { !error && !isLoading && 
          <Table 
            bordered
            scroll={{y:'80vh'}}
            pagination={false}
            dataSource={data!} 
            columns={columns}
            rowKey={(record : IJobCodeListItem) => record.id} 
            style={{minHeight:'75vh'}}
          />
        }
    </div>
  )
}

export default JobSearchResults

const rowTextStyle = {
  margin:'0px',
  fontSize: fonts.Medium,
  verticalAlign:'text-top'
} as const