import { useActions } from '../../common/hooks/useActions';
import { useTypedSelector } from '../../common/hooks/useTypedSelector';
import { useEffect } from 'react';
import { Typography, Divider, Avatar, Tooltip, Progress } from 'antd';
import { FaWrench, FaBalanceScale, FaFan, FaHardHat } from 'react-icons/fa';
import { Colors, fonts } from '../../common/styles';
import "../styles/job-overview.css";
import System from '../../pages/special-teams/System';

const { Paragraph } = Typography;

function JobOverview() {

  const { selectedJob, data } = useTypedSelector((state) => state.jobs.activeJobs);

  const usedLaborHours = 23;
  const laborHours = 56;
  const remainingLaborHours = 33;

  const completedSystems = 8;
  const systemCount = 12;

  const completedAirBalances = 6;
  const airBalanceCount = 8;

  const avatarSize = 42;
  const strokeWidth = 10;
  const progressWidth = 75;


  useEffect(() => {
  }, [])

  return (
    <div>
        {selectedJob != null && 
          <div>
            {/* <div className="job-header-container">
              <div>
                <h2>{selectedJob!.number}</h2>
                <h2>{selectedJob!.name}</h2>
              </div>
                <p>123 Main Street <br/>Seattle, WA 98108</p>
              <div></div>
            </div> */}
            {/* PANEL */}
            <h2 style={{color:'#6f6f6', fontWeight:'400', paddingLeft:'5px' }}>Overview</h2>

            <div className='panel-container'>


              {/* JOB NAME & INFO */}
            <div>
              <div className="progress-container">
                <p className="card-header">Job</p>
                <div className="job-header-container">
                    <h2>{selectedJob!.number}</h2>
                    <h2>{selectedJob!.name}</h2>
                    <p>123 Main Street <br/>Seattle, WA 98108</p>
                </div>
              </div>
            </div>


              {/* PROGRESS */}
              <div className="progress-container">
                <p className="card-header">Completed</p>
                <div className="avatar-container">
                  
                  {/* LABOR HOURS */}
                  <div className="avatar-stat">
                    <Tooltip title={`${remainingLaborHours} hours remaining`} color={Colors.Neutral600}>
                      <Progress type="circle"
                                width={progressWidth}
                                strokeColor={Colors.Primary500}
                                trailColor={`${Colors.Primary500}44`} 
                                strokeWidth={strokeWidth}
                                percent={41} 
                                format={() => 
                                    <Avatar size={avatarSize}
                                            icon={<FaHardHat />} 
                                            style={{color:Colors.Shade0, backgroundColor: Colors.Primary500}}
                                    />
                                  } 
                                />
                    </Tooltip> 
                    <p>Labor Hours</p>
                    <h2>{usedLaborHours}/{laborHours}</h2>
                  </div>
                  
                  {/* COMPLETED SYSTEMS */}
                  <div className="avatar-stat">
                    <Tooltip title={`${completedSystems} systems complete`} color={Colors.Neutral600}>
                      <Progress type="circle"
                                width={progressWidth}
                                strokeColor={Colors.Primary500}
                                trailColor={`${Colors.Primary500}44`} 
                                strokeWidth={strokeWidth}
                                percent={67} 
                                format={() => 
                                    <Avatar size={avatarSize}
                                            icon={<FaWrench />} 
                                            style={{color:Colors.Shade0, backgroundColor: Colors.Primary500}}
                                    />
                                  } 
                                />
                    </Tooltip> 
                    <p>Startup</p>
                    <h2>{completedSystems}/{systemCount}</h2> 
                  </div>

                  {/* COMPLETED AIR BALANCE */}
                  <div className="avatar-stat">
                    <Tooltip title={`${completedAirBalances} air balances completed`} color={Colors.Neutral600}>
                      <Progress type="circle"
                                width={progressWidth}
                                strokeColor={Colors.Primary500}
                                trailColor={`${Colors.Primary500}44`} 
                                strokeWidth={strokeWidth}
                                percent={75} 
                                format={() => 
                                    <Avatar size={avatarSize}
                                            icon={<FaFan />} 
                                            style={{color:Colors.Shade0, backgroundColor: Colors.Primary500}}
                                    />
                                  } 
                                />
                    </Tooltip>
                    <p>Air Balance</p>
                    <h2>{completedAirBalances}/{airBalanceCount}</h2>
                  </div>
                </div>
              </div>

              <div className="divider"></div>

              {/* INFORMATION */}
              <div className="info-container">
                  <p className='card-header'>Info</p>
              </div>
            </div>
          </div>
        }
        {selectedJob === null || selectedJob === undefined && 
          <div>
            The data was lost.
          </div>
        }
    </div>
  )
}

export default JobOverview


