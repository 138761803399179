export const Shared = {
    Login: '/login/',
    Register:'/register/',
    LostPassword:'/lostpassword/',
    VerifyPassword:'/verifypassword/',
    ResetPassword:'/resetpassword/',
    Unauthorized:'/unauthorized/',
}

const Domains = {
     
    SpecialTeams: '/specialteams/',
    ProjectManagement: '/pm/'
}

export const SpecialTeams = {

    Home: Domains.SpecialTeams + 'home/',
    Job: Domains.SpecialTeams +  'job',
    PunchList: Domains.SpecialTeams +  'punchlist/',
    System: Domains.SpecialTeams + 'system/',
    Unit: Domains.SpecialTeams +  'unit/'
}
