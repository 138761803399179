import { Dispatch } from 'redux';
import { CommissioningAPI } from '../api';
import { CommissioningActionTypes as ActionTypes } from '../action-types';
import { CommissioningAction as Action} from '../actions'
import { useScheduleUnits } from '../helpers/useScheduleUnits';
import { IEquipmentSystem, IEquipmentHeader, IUnitAirBalance, IUnitStartupInfo } from '../interfaces';

const generateRandomProgress = () : number => {
     const percent = Math.floor(Math.random() * 101);
     return percent % 6 === 0 ? 100 : percent; 
}

const generateSearchString = (result:any) : string => {
    const prefix = `${result.Name}, ${result.PrimaryEquipmentHeaderTag}`;
    const units = result.EquipmentHeaders.length === 0 ? '' : result.EquipmentHeaders.map((unit:any) =>{ return unit.Tag});
    return prefix + units.toString();
}

const assignPrimeMovers = (headers:any, currentHeaderId:number) : boolean => {

    if(headers.length === 0){
        return false;
    }

    const match = headers.filter((header:any) => header.ScheduleUnit !== null).filter((header : any) => header.ScheduleUnit.PrimeMoverEquipmentHeaderId === currentHeaderId);
    return match.length > 0 ? true : false;
}

const unpackStartupInfo = (startupInfo:any) : IUnitStartupInfo | null => {
    return startupInfo === null ? null : {
                    id:startupInfo.Id,
                    startupInfoConfigId:startupInfo.StartupInfoConfig === null ? null : startupInfo.StartupInfoConfig.Id,
                    serialNumber:startupInfo.SerialNumber,
                    supplyAirTempHeating:startupInfo.SupplyAirTemp_Heating,
                    returnAirTempHeating:startupInfo.ReturnAirTemp_Heating,
                    supplyAirTempCooling:startupInfo.SupplyAirTemp_Cooling,
                    returnAirTempCooling:startupInfo.ReturnAirTemp_Cooling,
                    lineSetLength:startupInfo.LineSetLength,
                    additionalChargeAdded:startupInfo.AdditionalChargeAdded,
                    washableFilter:startupInfo.WashableFilter,
                    filterSize:startupInfo.FilterSize,
                    filterQty:startupInfo.FilterQty,
                    thermostatType:startupInfo.ThermostatType,
                    ratedVoltage:startupInfo.RatedVoltage,
                    actualVoltage:startupInfo.ActualVoltage,
                    supplyAirFanAmps:startupInfo.SupplyAirFanAmps,
                    hasBelts:startupInfo.HasBelts,
                    beltQty:startupInfo.BeltQty,
                    economizer:startupInfo.Economizer,
                    economizerMinOutsideAirSetting:startupInfo.EconomizerMinOutsideAirSetting,
                    suctionPressureHeating:startupInfo.SuctionPressure_Heating,
                    suctionPressureCooling:startupInfo.SuctionPressure_Cooling,
                    dischargePressureHeating:startupInfo.DischargePressure_Heating,
                    dischargePressureCooling:startupInfo.DischargePressure_Cooling,
                    outsideAirTemp:startupInfo.OutsideAirTemp,
                    condenserFanAmps:startupInfo.CondenserFanAmps,
                    compressorAmps:startupInfo.CompressorAmps,
                    defrostTimerSet:startupInfo.DefrostTimerSet,
                    oneHundredPercentOutsideAir:startupInfo.OneHundredPercentOutsideAir,
                    gasPressureSet:startupInfo.GasPressureSet,
                    vfdServes:startupInfo.VFDServes,
                    fanMotorFLA:startupInfo.FanMotorFLA,
                    frequencySetting_Hz:startupInfo.FrequencySetting_Hz,
                    finalFLA:startupInfo.FinalFLA,
                    ratedAmps:startupInfo.RatedAmps,
                    actualAmps:startupInfo.ActualAmps,
                    outsideAirCFM:startupInfo.OutsideAirCFM,
                    exhaustFanAmps:startupInfo.ExhaustFanAmps,
                    controllerType:startupInfo.ControllerType,
                    returnAirTemp:startupInfo.ReturnAirTemp,
                    supplyAirTemp:startupInfo.SupplyAirTemp,
                    fanType:startupInfo.FanType,
    }
}

const unpackAirBalances = (airBalances:any) : IUnitAirBalance[] | null => {
    
    if(airBalances.length === 0){
        return null;
    }

    return airBalances.map((balance:any) => {
        return{
            id: balance.Id,
            testDate: balance.TestDate,
            testedBy: balance.TestedBy,
            designCFM: balance.DesignCFM,
            initialObservedCFM: balance.InitialObservedCFM,
            finalObservedCFM: balance.FinalObservedCFM,
            percentAccuracy: balance.PercentAccuracy,
        }
    })
}

const unpackEquipmentHeaders = (result:any) : IEquipmentHeader[] => {
    

    const headers:IEquipmentHeader[] = result.EquipmentHeaders.map((header:any) => {
        return {
            id:header.Id,
            tag: header.Tag,
            type: useScheduleUnits(header.Type),
            location:header.Location, 
            model:header.Model,
            mfr:header.Manufacturer,
            isPrimary:header.IsSystemPrimary,
            scheduleUnit: header.ScheduleUnit,
            grdServiceType: header.Type === 'GRD' ? header.ScheduleUnit.ServiceType : '',
            isPrimeMover: assignPrimeMovers(result.EquipmentHeaders, header.Id),
            startupConfig: header.StartupInfo === null ? null : header.StartupInfo.StartupInfoConfig,
            startupInfo: unpackStartupInfo(header.StartupInfo),
        }
    });

    return headers;
}

export const fetchJobEquipment = (jobnumber: string) => {
    return async (dispatch:Dispatch<Action>) =>{
        
        dispatch({
            type:ActionTypes.FETCH_JOB_EQUIPMENT
        });

        try {
            
            //query the data store
            // const { data } = await axios.get('/systems/headerswithunitsbyjobnumber/' + jobnumber, {});
            const { data } = await CommissioningAPI.getHeadersWithUnitsByJobNumber(jobnumber);

            //unpack the results
            const viewmodels:IEquipmentSystem[] = data.Headers.map((result: any) => {
                
                return {
                  id:result.Id,
                  tag:result.PrimaryEquipmentHeaderTag,
                  systemName:result.Name,
                  headers:unpackEquipmentHeaders(result),
                  airBalances:unpackAirBalances(result.AirBalances),
                  unitCount:result.EquipmentHeaders.length,
                  progress:generateRandomProgress(),
                  searchString:generateSearchString(result),
                };
            })

            //notify Redux by sending the appropriate dispatch.
            dispatch({
                type:ActionTypes.FETCH_JOB_EQUIPMENT_SUCCESS,
                payload:viewmodels
            });

        } 
        catch (err) {
            if(err instanceof Error){
                dispatch({
                type:ActionTypes.FETCH_JOB_EQUIPMENT_ERROR,
                payload: err.message
               })
            }
        }
    }
}

export const saveAirBalances = ({selectedSystem, editedAirBalances} : {selectedSystem:IEquipmentSystem | null, editedAirBalances:IUnitAirBalance[]}) => {
    
    return async (dispatch:Dispatch<Action>) => {
        dispatch({
            type:ActionTypes.SAVE_AIR_BALANCES
        });

        try{

            //API CALL
            const updateRequest = {
                airBalances: editedAirBalances
            }

            // const apiResponse = await axios.put('/airbalances/updaterange', updateRequest);
            //TODO: Implement Offline-First here.
            const apiResponse = await CommissioningAPI.updateAirBalances(updateRequest);

            //update the air balances of the selected system using the current state data (offline mode)
            const updatedBalances = selectedSystem!.airBalances.map((balance) => {
                const updated = editedAirBalances.find((u) => u.id === balance.id);
                return updated ? updated : balance;
            })
            
            const updatedSystem = {...selectedSystem!, airBalances:updatedBalances}

            dispatch({
                type:ActionTypes.SAVE_AIR_BALANCES_SUCCESS,
                payload: updatedSystem
            })

        }
        catch(err){
            if(err instanceof Error){
                dispatch({
                    type:ActionTypes.SAVE_AIR_BALANCES_ERROR,
                    payload:err.message
                })
            }
        }

    }
}

export const saveStartupInfo = ({updatedStartupInfo, equipmentHeader} : {updatedStartupInfo:IUnitStartupInfo, equipmentHeader:IEquipmentHeader}) => {
    return async (dispatch:Dispatch<Action>) => {
        dispatch({
            type:ActionTypes.SAVE_STARTUP_INFO
        });
        
        try{

            //API CALL
            const updateRequest = {
                payload:updatedStartupInfo
            }

            //TODO: Implement Offline-First here.
            // const response = await axios.put('/startup', updateRequest);
            const response = await CommissioningAPI.updateStartupInfo(updateRequest);

            //Update the equipment header
            const updatedEquipmentHeader = {...equipmentHeader, startupInfo:updatedStartupInfo};

            dispatch({
                type:ActionTypes.SAVE_STARTUP_INFO_SUCCESS,
                payload: updatedEquipmentHeader
            })

        }
        catch(err){
            if(err instanceof Error){
                dispatch({
                    type:ActionTypes.SAVE_STARTUP_INFO_ERROR,
                    payload:err.message
                })
            }
        }
    }
}

export const setSelectedSystem = (id: number | null) => {
    return (dispatch:Dispatch<Action>) => {
        dispatch({
            type:ActionTypes.SET_SELECTED_SYSTEM,
            payload:id
        });
    }
}

export const setSelectedEquipmentHeader = (id:number | null) => {
    return (dispatch:Dispatch<Action>) => {
        dispatch({
            type:ActionTypes.SET_SELECTED_EQUIPMENT_HEADER,
            payload:id
        })
    }
}