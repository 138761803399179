import { Alert, Tooltip} from 'antd';
import { HealthStatus } from '../common/enums'
import { alerts } from '../common/styles';

function JobHealth({health} : {health:HealthStatus}) {

    switch(health){
        case HealthStatus.Good:
            return <Alert 
                        showIcon
                        type="success"
                        message="Good"
                        />;
        case HealthStatus.AtRisk:
            return <Alert 
                        showIcon
                        type="warning"
                        message="At Risk"
                        />;
        case HealthStatus.NeedsAttention:
            return <Alert 
                        showIcon
                        type="error"
                        message="Needs Attention"
                        />;
        case HealthStatus.NoInformation:
            return <Alert 
                        showIcon
                        type="info"
                        message="No Information"
                        />;
        default: 
            return <Alert 
                        showIcon
                        type="info"
                        message="No Information"   
                    />;
    }
}

export default JobHealth