import { IApplicationUser } from '../interfaces';
import axios from './axios'
import * as routes from './routes';

const LOGIN_URL = 'banana/login';

//interfaces
export interface IUserLoginRequest{
  username:string,
  email:string,
  password:string,
}



export const TeamAPI = {
  
  loginUser: async function(loginRequest:IUserLoginRequest){

      const response = await axios.post(routes.Team.Login, loginRequest);
      return response;
  }
}