import { useState, useEffect } from 'react';
import { useActions } from '../../common/hooks/useActions';
import { useTypedSelector } from '../../common/hooks/useTypedSelector';
import { Drawer, Collapse, Tabs } from 'antd';
import { IEquipmentSystem } from '../../state/interfaces';
import UnitAirBalance from './UnitAirBalance';
import SystemDetailTree from './SystemDetailTree';
import ItemDetailPanel from './ItemDetailPanel';

interface SystemDetailPanelProps {
    visible:boolean;
    closePanel:() => void;
}

const { Panel } = Collapse;
const { TabPane } = Tabs;

function SystemDetailPanel({visible, closePanel} : SystemDetailPanelProps) {
  
  const { selectedSystem } = useTypedSelector((state) => state.commissioning.equipment)
  const { flags } = useTypedSelector((state) => state.configs.features);
  const itemDetailSinglePanelFeature = flags.find((f) => f.name === 'item-detail-single-panel')
  const itemDetailExpanders = flags.find((f) => f.name === 'item-detail-expanders')

    useEffect(() => {
    }, [visible, selectedSystem])

    return (
      <div>
        {selectedSystem !== null && 
          <Drawer title={selectedSystem!.systemName}
                  placement="right"
                  width={window.innerWidth > 2100 ? '40vw' : '75vw'}
                  onClose={closePanel}
                  visible={visible}
                  >
              <div>
                <div>
                  <Tabs>
                    <TabPane tab="Units" key="1">
                      { selectedSystem!.headers.length === 0 && <p>No Units</p>}
                      { selectedSystem!.headers.length > 0 && 
                        <div>
                          {itemDetailSinglePanelFeature!.on &&
                            <div style={{display:'flex', 
                                        alignItems:'flex-start', 
                                        height:'75vh'}}>
                                <div>
                                  <SystemDetailTree />
                                  <ItemDetailPanel />
                                </div>
                            </div> 
                          }
                          {itemDetailExpanders!.on && 
                            <div>
                              <h1>EXPANDERS</h1>
                              {/* <AirBalance /> */}
                            </div>
                          }

                        </div>
                        }
                    </TabPane>
                  </Tabs>
                </div>
              </div>        
          </Drawer>}
        { selectedSystem === null || selectedSystem === undefined && 
          <Drawer>
            <p>Error</p>
          </Drawer>
        }  
      </div>
  )
}

export default SystemDetailPanel